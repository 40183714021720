import { useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';

export type ApiGateway = {
  get: (url: string, appId: string) => Promise<any>;
  getImage: (url: string, appId: string) => Promise<any>;
  post: (
    url: string,
    appId: string,
    body: {}
  ) => Promise<any>;
  put: (
    url: string,
    appId: string,
    body: {}
  ) => Promise<any>;
  delete: (
    url: string,
    appId: string
  ) => Promise<any>;
};

export const useApiGateway = (): ApiGateway => {
  const { authState, oktaAuth } = useOktaAuth();
  const authorization = `Bearer ${oktaAuth.getAccessToken() ?? ''}`;
  let apiUrl = (window as any).globalConfig?.ApiGateway_URL || 'https://api-dev.nonprod-proag.com/';
  const postLogoutRedirectUri: string = (window as any).globalConfig?.Logout_Url;
  const signOut = useCallback(
        () => oktaAuth.signOut({ postLogoutRedirectUri }),
        [postLogoutRedirectUri]);

  if (!authState?.isAuthenticated) {
    void signOut();
  }

  function handleResponse(response: {
    text: () => Promise<any>;
    ok: any;
    statusText: any;
    headers: any;
    status: any;
    url: any;
  }) {

    if(response.status === 401){
      signOut();
    }

    return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }

  function handleImageResponse(response: {
    arrayBuffer: () => Promise<any>;
    ok: any;
    statusText: any;
    status: any;
    headers: any;
  }){
    
    return response.arrayBuffer().then((buffer) => {
      const data = buffer;

      if(!response.ok) {
        return "no image";
      }
      return btoa(
        String.fromCharCode(...new Uint8Array(data))
    );
    })
  }

  return {
    get: function get(
      url: string,
      appId: string,
    ) {
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: authorization,
          ApplicationId: appId,
        },
      };
      return fetch(apiUrl + url, requestOptions).then(handleResponse);
    },

    getImage: function get(
      url: string,
      appId: string
    ) {
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: authorization,
          ApplicationId: appId,
        },
      };
      return fetch(apiUrl + url, requestOptions).then(handleImageResponse)

    },

    post: function post(
      url: string,
      appId: string,
      body: {}
    ) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization,
          ApplicationId: appId,
        },
        body: JSON.stringify(body),
      };
      return fetch(apiUrl + url, requestOptions).then(handleResponse);
    },

    put: function put(
      url: string,
      appId: string,
      body: {},
    ) {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization,
          ApplicationId: appId,
        },
        body: JSON.stringify(body),
      };
      return fetch(apiUrl + url, requestOptions).then(handleResponse);
    },

    // prefixed with underscored because delete is a reserved word in javascript
    delete: function _delete(
      url: string,
      appId: string
    ) {
      const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: authorization, ApplicationId: appId, },
      };
      return fetch(apiUrl + url, requestOptions).then(handleResponse);
    },
  };
};
