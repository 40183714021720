import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { api } from "./api";

export const useSaveContentMutation = () => {
  const { mutate, isLoading, data } = useMutation(
    ["saveContent"],
    api.saveContent,
    {
      onSuccess: () =>
      {
      },
      onError: (error) => toast.error('An error has occurred with your request. Error message:' + error),
    }
  );

  return { saveContentQuery: mutate, isSavingContent: isLoading, saveContentData: data };
};