import { elementAcceptingRef } from "@mui/utils";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { useEmployeeSearchQuery } from "./api/useEmployeeSearchQuery";
import style from "./EmployeeSearch.module.scss";
import { EmployeeTable } from "./EmployeeTable";
import { useEmployeeSearchOptionsQuery } from "./api/useEmployeeSearchOptionsQuery";
import { EmployeeDetailModal } from "./components/EmployeeDetailModal";
import { useApiGateway } from "app/apiGateway";
import { api } from "./api";

interface Employee {
  givenName: string;
  surname: string;
  businessPhones: [];
  mail: string;
  jobTitle: string;
  department: string;
}

export const EmployeeSearch = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [employeeData, setEmployeeData] = useState<Employee[] | any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | any>();
  const [modalShow, setModalShow] = React.useState(false);
  const [profilePhoto, setProfilePhoto] = useState();
  const apiGateway = useApiGateway();

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      boxShadow: state.isFocused ? `0 0 0 1px '#004c23'` : 0,
      borderColor: state.isFocused ? "#004c23" : "#ced4da",
      "&:hover": {
        borderColor: "#004c23",
      },
    }),
    option: (base: any, state: any) => ({
      ...base,
      color: "#000000",
      backgroundColor: state.isSelected || state.isFocused  ? "#eff1ed" : undefined,
      "&:hover": {
        backgroundColor: "#eff1ed",
      },
    }),
  };

  const { employeeSearchOptions } = useEmployeeSearchOptionsQuery();

  const formatJobOptions = () => {
    const options = employeeSearchOptions?.filter(
      (option) => option.jobTitle !== null
    );
    const filterOptions = Array.from(
      new Set(options?.map((item: any) => item.jobTitle))
    ).sort();
    const jobTitles = filterOptions?.map((option: any) => {
      return {
        label: option,
        value: option,
      };
    });

    return jobTitles;
  };

  const formatDepartments = () => {
    const options = employeeSearchOptions?.filter(
      (option) => option.department !== null && option.department !== "-"
    );
    const filterOptions = Array.from(
      new Set(options?.map((item: any) => item.department))
    ).sort();
    const departments = filterOptions?.map((option: any) => {
      return {
        label: option,
        value: option,
      };
    });

    return departments;
  };

  const [selectedJobTitles, setSelectedJobTitles] = useState<any[]>([]);

  const { employees } = useEmployeeSearchQuery();
  useEffect(() => {
    if (employees) {
      setEmployeeData(employees);
    }
  }, [employees]);

  const handelOnSearchClick = (e: any) => {
    e.preventDefault();
    if (firstName || lastName || jobTitle || department) {
      const filterSearch = employees?.filter(
        (record: any) =>
          record.givenName?.toLowerCase().includes(firstName.toLowerCase()) &&
          record.surname?.toLowerCase().includes(lastName.toLowerCase()) &&
          (record.jobTitle?.toLowerCase() === jobTitle.toLowerCase() || !jobTitle) &&
          (record.department?.toLowerCase() === department.toLowerCase() || !department)
      );
      setEmployeeData(filterSearch);
    } else {
      setEmployeeData(employees);
    }
  };

  const handleKeydown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handelOnSearchClick(e);
    }
  };

  const displayName =
    selectedEmployee?.givenName + " " + selectedEmployee?.surname;
  const job = selectedEmployee?.jobTitle;
  const dept = selectedEmployee?.department;
  const phone = selectedEmployee?.businessPhones;
  const email = selectedEmployee?.mail;

  const handleSelection = async (e: any, open: any) => {
    if (e) {
      const response = await api.getEmployeeDetail(apiGateway, e.mail);
      setProfilePhoto(response);
      setSelectedEmployee(e);
    }

    setModalShow(open);
  };

  return (
    <>
      <EmployeeDetailModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        name={displayName}
        job={job}
        dept={dept}
        phone={phone}
        email={email}
        customStyle={style.employeeModal}
        profilePhoto={profilePhoto}
      />
      <div className={style.employeeSearchWrapper}>
        <div className={style.employeeSearchContainer}>
          <h4 className={style.employeeSearchTitle}>ProAg Employees</h4>
          <Form>
            <Form.Group as={Row} data-testid="keydown" className="mb-1" onKeyDown={handleKeydown}>
              <Form.Label sm={4} md={3} lg={2} xxl={1} column>
                First Name:
              </Form.Label>
              <Col sm={6} lg={3}>
                <Form.Control
                  data-testid="firstname"
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1" onKeyDown={handleKeydown}>
              <Form.Label sm={4} md={3} lg={2} xxl={1} column>
                Last Name:
              </Form.Label>
              <Col sm={6} lg={3}>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1" onKeyDown={handleKeydown}>
              <Form.Label sm={4} md={3} lg={2} xxl={1} column>
                Job Title:
              </Form.Label>
              <Col sm={6} lg={3}>
                <Select
                data-testid="select"
                  isSearchable={true}
                  styles={customStyles}
                  isClearable={true}
                  options={formatJobOptions()}
                  onChange={(value) => {
                    if (value) {
                      setJobTitle(value.value);
                    } else {
                      setJobTitle("");
                    }
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1" onKeyDown={handleKeydown}>
              <Form.Label sm={4} md={3} lg={2} xxl={1} column>
                Department:
              </Form.Label>
              <Col sm={6} lg={3}>
                <Select
                  isSearchable={true}
                  styles={customStyles}
                  isClearable={true}
                  options={formatDepartments()}
                  onChange={(value) => {
                    if (value) {
                      setDepartment(value.value);
                    } else {
                      setDepartment("");
                    }
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-1"
              controlId="formHorizontalEmail"
            >
              <Form.Label sm={4} md={3} lg={2} xxl={1} column></Form.Label>
              <Col sm={6} lg={3}>
                <Button data-testid="searchdetails" onClick={handelOnSearchClick}>Search</Button>
              </Col>
            </Form.Group>
          </Form>
        </div>
        <EmployeeTable
          employeeData={employeeData}
          setSelectedEmployee={handleSelection}
        />
      </div>
    </>
  );
};
