import { FallbackProps } from "react-error-boundary";
import style from "./ErrorFallback.module.scss";
import { ExclamationCircle } from "react-bootstrap-icons";
import { Page } from "components/navigation/Page";

interface ErrorFallbackProps {
  a: FallbackProps;
  b: CustomProps;
}
interface CustomProps {
  category: string;
}
export const ErrorFallback = ({error, resetErrorBoundary}: FallbackProps) => {
  return (
    <>
        <Page
        title="Error Alert"
        content={
          <>
            We apologize. Something went wrong with your request. Please contact
            the ProAg <a href="mailto: helpdesk@proag.com">Help Desk</a>.
          </>
        }
      />
    </>
  );
};