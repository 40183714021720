import style from "./Layout.module.scss";

export const Footer = () => {
  return (
    <div className={style.footerContainer}>
      <div className={style.footer}>
        &copy; {new Date().getFullYear()} ProAg. All rights reserved. A member
        of the Tokio Marine HCC group of companies.
        <br />
        ProAg is an equal opportunity provider.
        <br />
        <a
          className={style.link}
          href="https://www.proag.com/privacy-non-discrimination-statements/"
          target="_blank"
        >
          Privacy and Non-Discrimination Statements
        </a>
        <br />
      </div>
    </div>
  );
};
