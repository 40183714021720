import { useEffect, useState } from "react";
import style from "./Calendar.module.scss";
import { Link, useLocation, useSearchParams } from "router6";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useSingleCalendarEventQuery } from "./api/useSingleCalendarEventQuery";
import parse from "html-react-parser";
import { createEvent } from "ics";
import { useEventAttachmentsQuery } from "./api/useEventAttachmentsQuery";
import { useNavigate } from "react-router";

export const EventDetail = () => {
  const navigate = useNavigate();
  const eventId = new URLSearchParams(window.location.search).get(
    "eventId"
  );
  //const eventId = searchParams.get("eventId");

  const [hasAttachments, setHasAttachments] = useState(false);
  const [isAlert, setIsAlert] = useState(false);

  const { event, isLoading } = useSingleCalendarEventQuery(eventId);

  const { eventAttachments, eventAttachmentsLoading } =
    useEventAttachmentsQuery(eventId, hasAttachments);

  useEffect(() => {
    if (event) {
      const isAlertCategory = event.categories?.filter((x: any) =>
        x.includes("Notifications")
      );
      setIsAlert(isAlertCategory?.length ?? false);
      setHasAttachments(event.hasAttachments);
    }
  }, [event]);

  const formatDescription = () => {
    const htmlString = event.body.content;
    const content = htmlString.replace(/href/g, "target='_blank' href");
    const body = content.match(/<body[^>]*>[\s\S]*<\/body>/gi);
    if (body) {
      var strippedHtml = body[0].replace(/<[^>]+>/g, "");
      const description = strippedHtml != "\r\n\r\n&nbsp;\r\n\r\n";
      if (!description) return "";
    }

    return `${body}`;
  };
  const getEventTime = (rawTime: string) => {
    const time = new Date(rawTime);
    return [
      time.getFullYear(),
      time.getMonth() + 1,
      time.getDate(),
      time.getHours(),
      time.getMinutes(),
    ];
  };

  const getDuration = (rawStartTime: any, rawEndTime: any) => {
    const start = new Date(rawStartTime);
    const end = new Date(rawEndTime);
    return {
      hours: end.getHours() - start.getHours(),
      minutes: end.getMinutes() - start.getMinutes(),
    };
  };

  const getRecurrenceRule = (rawRecurrence: any) => {
    if (!rawRecurrence) return "";
    let rRule = rawRecurrence
      ? "FREQ="
          .concat(rawRecurrence.pattern.type)
          .concat(";INTERVAL=")
          .concat(rawRecurrence.pattern.interval)
      : "";
    if (rawRecurrence.range.type == "endDate") {
      const rEndDate = new Date(rawRecurrence.range.endDate);
      const rUntil =
        rEndDate.getFullYear() +
        "-" +
        rEndDate.getMonth() +
        "-" +
        rEndDate.getDate();
      rRule = rRule.concat(";UNTIL=").concat(rUntil);
    }
    return rRule;
  };

  const mapEventDataForICS = (rawEvent: any): any => {
    let htmlContent = event.body.content.replace(/\n/g, "");
    htmlContent = htmlContent.replace(/\r/g, "");
    return {
      method: "REQUEST",
      start: getEventTime(formatDate(event.start.dateTime)),
      duration: getDuration(rawEvent.start.dateTime, rawEvent.end.dateTime),
      recurrenceRule: getRecurrenceRule(rawEvent.recurrence),
      title: rawEvent.subject,
      htmlContent,
      location: event.location.displayName,
      url: rawEvent.start.webLink,
      geo: rawEvent.cordinates,
      categories: rawEvent.categories,
      status: rawEvent.isCancelled ? "CANCELLED" : "CONFIRMED",
      busyStatus: rawEvent.showAs,
      organizer: {
        name: rawEvent.organizer.emailAddress.name,
        email: rawEvent.organizer.emailAddress.address,
      },
      attendees: rawEvent.attendees.map((item: any) => {
        return {
          name: item.emailAddress.name,
          email: item.emailAddress.address,
        };
      }),
    };
  };
  
  function convertDateToLocalDate(date: Date) {
    const showTime = date.getHours()
        + ':' + date.getMinutes()
        + ":" + date.getSeconds();
        if(showTime !== "0:0:0"){
          var newDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000
          );
          return newDate;
        }    
    return date;
  }

  const formatDate = (date: any) => {
   const convertedDate = new Date(convertDateToLocalDate(new Date(date)))
   return new Date(convertedDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  };

  const downloadFile = async () => {
    const filename = "owssvr.ics";
    const file: any = await new Promise((resolve, reject) => {
      createEvent(mapEventDataForICS(event), (error, value) => {
        if (error) {
          reject(error);
        }

        resolve(new File([value], filename, { type: "plain/text" }));
      });
    });
    const url = URL.createObjectURL(file);

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);
  };

  const openAttachmentLink = (content: any) => {
    const w = window.open("about:blank");
    const linkSource = `data:application/pdf;base64,${content}`;

    if (w) {
      w.document.body.appendChild(document.createElement("iframe")).src =
        linkSource;
      w.document.getElementsByTagName("iframe")[0].style.width = "100%";
      w.document.getElementsByTagName("iframe")[0].style.height = "100%";
    }
  };

  return (
    <>
      <div className={style.EventDetailContainer}>
        <Row className="d-flex justify-content-center">
          <Col className="d-flex justify-content-center">
            {event && !isLoading && !eventAttachmentsLoading && (
              <div className={style.sectionTable}>
                <div className={style.sectionHeading}>{event.subject}</div>
                <div className={style.sectionBody}>
                  <div className={style.DisplayTable}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>Title</td>
                          <td>{event.subject}</td>
                        </tr>
                        {event.location.displayName && (
                          <tr>
                            <td>{isAlert ? "System" : "Location"}</td>
                            <td>{event.location.displayName}</td>
                          </tr>
                        )}
                        {formatDescription() && (
                          <tr>
                            <td>Description</td>
                            <td>{parse(formatDescription())}</td>
                          </tr>
                        )}
                        <tr>
                              <td>Category</td>
                              <td>{event.categories}</td>
                            </tr>
                        {!isAlert && (
                          <>
                            <tr>
                              <td>Start Time</td>
                              <td>{formatDate(event.start.dateTime)}</td>
                            </tr>
                            <tr>
                              <td>End Time</td>
                              <td>{formatDate(event.end.dateTime)}</td>
                            </tr>
                            <tr>
                              <td>All Day Event</td>
                              <td>{event.isAllDay ? "Yes" : "No"}</td>
                            </tr>
                          </>
                        )}

                        <tr>
                          <td>Attachments</td>
                          <td>
                            {hasAttachments &&
                              eventAttachments &&
                              eventAttachments.value && (
                                <>
                                  {eventAttachments.value
                                    ?.filter(
                                      (y: any) =>
                                        y.contentType == "application/pdf"
                                    )
                                    .map((attachment: any) => (
                                      <div className={style.attachments}>
                                        <a
                                          onClick={() =>
                                            openAttachmentLink(
                                              attachment.contentBytes
                                            )
                                          }
                                        >
                                          {attachment["name"]}
                                        </a>
                                      </div>
                                    ))}
                                </>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className={style.EventButtonsRow}>
                    <div className={style.EventButtons}>
                      <Button
                        onClick={() => navigate(-1)}
                        className={style.BackButton}
                      >
                        <span>Back</span>
                      </Button>
                      <Button
                        className={style.AddButton}
                        onClick={downloadFile}
                      >
                        Add to Calendar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
