import style from "../search/SearchResults.module.scss";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { SearchResultTable } from "./component/SearchResultTable";
import { useEffect, useState } from "react";
import { MetadataKeyValue, SearchResultRequest } from "./api/api";
import Select, { components } from "react-select";
import { useMetadataOptionsQuery } from "./api/useMetadataOptionsQuery";
import { Check, Search } from "react-bootstrap-icons";
import { useMetadataSearchQuery } from "./api/useMetadataSearchQuery";
import { ReactSelectComponent } from "./component/ReactSelectComponent";
import { LoadingSpinner } from "components";

export type SelectValue = {
  label: any;
  value: any;
}[];

export type SelectedDropdownValue = {
  fieldKey: string;
  selectedValues: SelectValue;
}[];

export const SearchResults = () => {
  let searchParam = new URLSearchParams(window.location.search).get(
    "searchText"
  );

  let defaultParam = new URLSearchParams(window.location.search).get("default");

  const hiddenFields = [
    "access",
    "alternateSearchTerms",
    "publishedDateOverride",
    "status",
    "title",
  ];

  const [isNewSearch, setIsNewSearch] = useState(false);
  const [searchText, setSearchText] = useState(searchParam ?? "");
  const [isMetadataSearch, setIsMetadataSearch] = useState(true);
  const [documentSearchType, setDocumentSearchType] = useState(defaultParam ?? "");
  const [originalSelectedValues, setOriginalSelectedValues] = useState<MetadataKeyValue[]>([]);

  const [selectedValues, setSelectedValues] = useState<MetadataKeyValue[]>([]);
  const [selectedValuesLabelValue, setSelectedValuesLabelValue] =
    useState<SelectedDropdownValue>([]);
  const [clear, setClear] = useState(false);
  const [isDropdownSearchOpen, setIsDropdownSearchOpen] = useState(false)

  const request: SearchResultRequest = {
    searchText: searchText,
    offset: 0,
    pageLimit: 10,
    isMetadataSearch: isMetadataSearch && documentSearchType == "",
    documentSearchType: documentSearchType,
    metadataValues: [],
  };


  const [searchResultRequest, setSearchResultRequest] =
    useState<SearchResultRequest>(request);

  const { data, areOptionsLoading } = useMetadataOptionsQuery();

  const { metadataResults, isSearchResultLoading } =
    useMetadataSearchQuery(searchResultRequest);

  const allDropdowns = data?.fields?.length
    ? data?.fields
        ?.filter(
          (x: any) => x.type === "multiSelect" && !hiddenFields.includes(x.key)
        )
        .sort()
    : [];

  const [dropdowns, setDropdowns] = useState<any>(
    searchParam == "" ? allDropdowns : []
  );

  const returnedMetadata = metadataResults?.metadataFields?.length
    ? metadataResults?.metadataFields
        ?.filter((x: any) => !hiddenFields.includes(x.key))
        .sort((a, b) => (a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1))
    : [];

  const clearAllFields = () => {
    const request: SearchResultRequest = {
      searchText: "",
      offset: 0,
      pageLimit: 20,
      isMetadataSearch: isMetadataSearch && documentSearchType == "",
      documentSearchType: "",
      metadataValues: [],
    };
    setSearchResultRequest(request);
    setSearchText("");
    setDropdowns(allDropdowns);
    setOriginalSelectedValues([])
    setSelectedValues([]);
    setSelectedValuesLabelValue([]);
    setDocumentSearchType("");
    setClear(!clear);
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    const selectedOptions = newValue?.map((option: any) => option.label);

    const keyValue: MetadataKeyValue = {
      key: actionMeta.name,
      value: selectedOptions,
    };

    const list = selectedValues?.filter((value: any) => {
      return value.key != actionMeta.name;
    });

    const newList = keyValue.value.length ? list?.concat(keyValue) : list;
    setSelectedValues(newList);
    setOriginalSelectedValues(newList)
  };

  const handlePaginationChange = (e: any) => {
    const offset = e > 0 ? e * 20 : 0;
    const request: SearchResultRequest = {
      searchText: searchText,
      offset: offset,
      pageLimit: 20,
      isMetadataSearch: isMetadataSearch && documentSearchType == "",
      documentSearchType: documentSearchType,
      metadataValues: originalSelectedValues,
    };
    setSearchResultRequest(request);
  };

  const handleClick = () => {
    setIsNewSearch(!isNewSearch)
    const request: SearchResultRequest = {
      searchText: searchText,
      offset: 0,
      pageLimit: 20,
      isMetadataSearch: isMetadataSearch && !isDropdownSearchOpen,
      documentSearchType: isDropdownSearchOpen ? "advancedSearch" : documentSearchType,
      metadataValues: selectedValues,
    };
    setSearchResultRequest(request);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleClick();
    }
  };

  const handleTitleTextAccordion = () => {
    setIsMetadataSearch(true)
    setClear(!clear)
    setIsDropdownSearchOpen(false)
    setDropdowns(allDropdowns)
    setOriginalSelectedValues([])
    setSelectedValues([]);
    setSelectedValuesLabelValue([]);
  }

  const handleAdvSearchAccordion = () => {
    setIsDropdownSearchOpen(true)
    setSearchText("")
    setDocumentSearchType("advancedSearch")
    setIsMetadataSearch(false)
  }

  const handleAllText = () => {
    setDocumentSearchType("")
    setIsMetadataSearch(false)
  }

  const handleTitleOnly = () => {
    setDocumentSearchType("")
    setIsMetadataSearch(true)
  }

  useEffect(() => {
    if (
      (searchParam == "" &&
        selectedValues.length < 1 &&
        returnedMetadata?.length < 1) ||
        (documentSearchType !== "" && documentSearchType !== "advancedSearch") ||
      !isDropdownSearchOpen
    ) {
      setDropdowns(allDropdowns);
    } else if (returnedMetadata?.length) {
      const values: MetadataKeyValue[] = returnedMetadata.map((x: any) => {
        return {
          key: x.key,
          value: x.values,
        };
      });

      const valuesLabel: SelectedDropdownValue = returnedMetadata.map(
        (x: any) => {
          return {
            fieldKey: x.key,
            selectedValues: x.values.map((y: any) => {
              return {
                label: y,
                value: y,
              };
            }),
          };
        }
      );

      setDropdowns(returnedMetadata);
      setSelectedValues(values);
      setSelectedValuesLabelValue(valuesLabel);
    } else if (!returnedMetadata?.length) {
      setSelectedValues([]);
      setSelectedValuesLabelValue([]);
    }
  }, [metadataResults, data]);

  const { Option } = components;

  const Svg = (p: JSX.IntrinsicElements["svg"]) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );

  const customComponents = {
    MultiValueContainer: (props: any) => {
      const values = props.selectProps.value;

      if (values) {
        return values[values.length - 1].label === props.data.label
          ? props.data.label
          : props.data.label + ", ";
      } else return "";
    },
    Option: (props: any) => {
      return (
        <Option {...props}>
          {props.isSelected && <Check />}
          {props.data.label}
        </Option>
      );
    },
    DropdownIndicator: (props: any) => {
      return (
        <div style={{ color: "#d1e7dd", height: 24, width: 32 }}>
          <Svg>
            <path
              d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </Svg>
        </div>
      );
    },
  };

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      boxShadow: state.isFocused ? `0 0 0 1px '#004c23'` : 0,
      borderColor: state.isFocused ? "#004c23" : "#ced4da",
      "&:hover": {
        borderColor: "#004c23",
      },
    }),
    option: (base: any, state: any) => ({
      ...base,
      color: "#000000",
      backgroundColor: state.isFocused
        ? "rgb(0, 76, 35, 0.25)"
        : state.isSelected
        ? "rgb(0, 76, 35, 0.1)"
        : "#fff",
      "&:hover": {
        backgroundColor: "rgb(0, 76, 35, 0.25)",
      },
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      minWidth: "20%",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#fff",
    }),
  };

  return (
    <>
      <Container className={style.containerClass}>
        {(isSearchResultLoading || areOptionsLoading) && (
          <div className={style.loadingContainer}>
            <LoadingSpinner message="Loading Results...Please Wait" />
          </div>
        )}
        <div
          style={{
            opacity: isSearchResultLoading || areOptionsLoading ? "0.2" : "1",
          }}
          className={style.mainContainer}
        >
          <div className={style.links}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={handleTitleTextAccordion} className={style.accordionHeader}>Title/Keyword or Text Search</Accordion.Header>
              <Accordion.Body className={style.accordionBody}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Form>
                      <InputGroup>
                        <Form.Control
                          placeholder="Search..."
                          value={searchText}
                          className="mb-3"
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                      </InputGroup>
                    </Form>
                  </Col>
                  <Col xs={6} sm={4} md={6} lg={3} xl={3} className="d-flex">
                    <Form.Group className={style.radioGroup}>
                      <Form.Check
                        inline
                        type="radio"
                        label="Title/Keyword"
                        value="titleOnly"
                        checked={isMetadataSearch}
                        onChange={handleTitleOnly}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="All Text"
                        value="allText"
                        checked={!isMetadataSearch}
                        onChange={handleAllText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
              <Accordion.Item eventKey="1">
              <Accordion.Header className={style.accordionHeader} onClick={handleAdvSearchAccordion}>Advanced Search</Accordion.Header>
              <Accordion.Body className={style.accordionBody}>
                  <>
                    {isSearchResultLoading ? (
                      <Row className={style.loadingState}></Row>
                    ) : (
                      <Row className="mb-3">
                        {dropdowns &&
                          dropdowns
                            .map((dropdown: any) => (
                              <Col xs={6} sm={6} md={4} lg={3} xl={4}>
                                <ReactSelectComponent
                                  metadataKey={dropdown.key}
                                  selectedValues={selectedValues}
                                  selectedValuesLabelValue={
                                    selectedValuesLabelValue
                                  }
                                  optionsData={data}
                                  customComponents={customComponents}
                                  customStyles={customStyles}
                                  results={metadataResults}
                                  clearAll={clear}
                                  onChange={handleChange}
                                />
                              </Col>
                            ))}
                      </Row>
                    )}
                  </>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
          </div>
          <div className={style.searchButtonGroup}>
            <button
              tabIndex={0}
              className={style.clearAllBtn}
              onClick={() => clearAllFields()}
            >
              Clear All
            </button>
            <span
              className="input-group-btn"
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              <Button className={style.advancedSearchBtn} onClick={handleClick}>
                <span className={style.advancedSearchBtnText}>
                  Search
                </span>
                <Search />
              </Button>
            </span>
          </div>

          <div className={style.sectionTable}>
            <SearchResultTable
              searchResultRequest={searchResultRequest}
              results={metadataResults}
              isSearchTypeAllText={!isMetadataSearch}
              resetTableFilters={clear}
              onPaginationChange={handlePaginationChange}
              isNewSearch={isNewSearch}
            />
          </div>
        </div>
      </Container>
    </>
  );
};
