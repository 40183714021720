import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { ApiGateway, useApiGateway } from './useApiGateway';
import { Party } from './constants/ApplicationIdValues';

type RoleResponse = [
  {
    applicationName: string;
    roleName: string;
  }
];

const getRole = async (apiGateway: ApiGateway) =>
  (await apiGateway.get('applications/ProAgPortal/roles', Party)) as RoleResponse;

export type RoleName = 'Administrator' | 'Internal' | 'External' | '';

export const roles = {
  Internal: 'Internal',
  External: 'External',
  Admin: 'Administrator',
};

export const useRole = () => {
  const apiGateway = useApiGateway();

  const { data, isFetching } = useQuery(
    ['role'],
    async () => {
      const response = await getRole(apiGateway);
      return response[0].roleName.trim() as RoleName;
    },
      {
          // onError: (error) => toast.error('An error has occurred with your request. Please try again or contact your sales representative. Error message:' + error)
      }
  );

  return { role: data, isRoleLoading: isFetching };
};
