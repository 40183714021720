import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import style from "views/Calendar/Calendar.module.scss";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { CalendarEventsRequest, CalendarViewEvent } from "../api/api";
import { useNavigate } from "router6";
import TimezoneSelect from "views/agCalender/TimeZoneSelect";
import { useCalendarEventsQuery } from "../api";
import { eventsService } from "../service/EventsService";
import { RoleName } from "app/apiGateway/useRole";

interface CalendarComponentProps {
  selectedDate: Date;
  handleDateChange: any;
  eventPropGetter: any;
  role: RoleName | undefined;
  selectedItem: any;
  eventsRequest: CalendarEventsRequest;
}

export const CalendarComponent: React.FC<CalendarComponentProps> = ({
  selectedDate,
  handleDateChange,
  eventPropGetter,
  role,
  selectedItem,
  eventsRequest,
}) => {

  const localizer = momentLocalizer(moment);
  const defaultTZ: string = moment.tz.guess();
  const [timezone, setTimezone] = useState<string>(defaultTZ);
  const navigator = useNavigate();

  const ROLE_EXTERNAL = "External";

  const { calEvent: activeEvent, isLoading } =
    useCalendarEventsQuery(eventsRequest);

  const [calenderEvents, setCalenderEvents] = useState<any[] | undefined>([]);

  useEffect(() => {
    const events = activeEvent?.value;
    let convertEvents: any[] | undefined = events
      ? eventsService(events)
      : undefined;
    if (convertEvents && selectedItem) {
      convertEvents = convertEvents?.filter((cal: any) => {
        if (
          cal.categories &&
          cal.categories.indexOf(selectedItem.label) !== -1
        ) {
          return true;
        } else {
          return false;
        }
      });
      setCalenderEvents(convertEvents);
    } else if (convertEvents && role === ROLE_EXTERNAL) {
      convertEvents = convertEvents?.filter((cal: any) => {
        if (
          cal.categories &&
          (cal.categories.indexOf("Insurance") !== -1 ||
            cal.categories.indexOf("Agent Training") !== -1)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setCalenderEvents(convertEvents);
    } else if (convertEvents && role) {
      setCalenderEvents(convertEvents);
    } else {
      setCalenderEvents([]);
    }
  }, [activeEvent, role, selectedItem]);

  const handleSelectEvent = (event: any) => {
    navigator(
      `/eventDetail?eventId=${event.id}&epochStartTime=${moment(
        event.start
      ).valueOf()}&epochEndTime=${moment(event.end).valueOf()}`
    );
  };

  useEffect(() => {
    moment.tz.setDefault(timezone);
  }, [timezone]);
  return (
    <>
      <div data-testid="calender" className={style.BigCalendar}>
        <div className={style.BigCalendarSelect}>
          <TimezoneSelect
            defaultTZ={defaultTZ}
            setTimezone={(timezone) => {
              moment.tz.setDefault(timezone);
              setTimezone(timezone);
            }}
            timezone={timezone}
          />
        </div>
        <Calendar
          events={calenderEvents}
          className={style.CalendarResponsive}
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventPropGetter}
          views={{
            month: true,
            week: true,
            day: true,
          }}
          date={selectedDate}
          onNavigate={handleDateChange}
          onSelectEvent={handleSelectEvent}
          selectable
          style={{ height: "600px", margin: "0 auto" }}
        />
      </div>
    </>
  );
};
