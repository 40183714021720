import { ApiGateway } from "app/apiGateway/useApiGateway";
import { Document } from "app/apiGateway/constants/ApplicationIdValues";

export type BoxEntityResponse = {
  id: number;
  item_status: string;
  description: string;
  modified_at: string;
  name: string;
  type: string;
}[];

export type MetadataDocumentResponse = {
  totalCount: any;
  files: FileEntityResponse;
  metadataFields: MetadataFieldEntityResponse;
};

export type FileEntityResponse = {
  id: number;
  fileType: string;
  name: string;
  description: string;
  modifiedAt: string;
  contentModifiedAt: string;
  createdAt: string;
  contentCreatedAt: string;
  metadata: MetadataEntity;
}[];

export type MetadataEntity = {
  templateName: string;
  metadataFields: any;
};

export type MetadataFieldEntityResponse = {
  key: string;
  values: string[];
}[];

export type MdFilter = {
  key: string;
  value: string[];
};

export type BoxResponse = {
  entries: BoxEntityResponse;
  limit: number;
  offset: number;
  //order: [];
  total_count: number;
};

export type MetadataKeyValue = {
  key: string;
  value: string[];
};

export type SearchResultRequest = {
  searchText: string;
  offset: number;
  pageLimit: number;
  isMetadataSearch: boolean;
  documentSearchType: string;
  metadataValues: MetadataKeyValue[];
};

export type MetadataSearchRequest = {
  searchText: string;
  metadataValues: MetadataKeyValue[];
};

export type SearchResultResponse = {
  data: any;
  isSearchResultLoading: boolean;
  mappedData: any;
};

export type MetadataOptionEntity = {
  displayName: string;
  hidden: boolean;
  key: string;
  options: [];
  type: string;
};

export type MetadataRequestBody = {
  format: string;
  isText: boolean;
  key: string;
  operator: string;
  value: string[];
}[];

const metadataSearchUrl = "categories/ProAgPortal/metadataSearch";

const metadataTemplateUrl =
  "categories/ProAgPortal/metadata?template=proagportalmetadata";

const getMetadataTemplate = async (apiGateway: ApiGateway) => {
  return await apiGateway.get(metadataTemplateUrl, Document);
};

const getMetadataSearchResults = async (
  apiGateway: ApiGateway,
  searchResultRequest: SearchResultRequest
) => {
  if (
    (!searchResultRequest.searchText &&
      !searchResultRequest.metadataValues.length &&
      searchResultRequest.isMetadataSearch) ||
    (!searchResultRequest.searchText &&
      !searchResultRequest.isMetadataSearch &&
      (!searchResultRequest.documentSearchType || searchResultRequest.documentSearchType === "basicSearch")) ||
      (!searchResultRequest.isMetadataSearch && !searchResultRequest.metadataValues.length && searchResultRequest.documentSearchType === "advancedSearch")
  ) {
    const emptyResponse: MetadataDocumentResponse = {
      totalCount: 0,
      files: [],
      metadataFields: [],
    };
    return emptyResponse;
  }
  if (searchResultRequest.isMetadataSearch) {
    const formatedInput = searchResultRequest.searchText.replace(/ /g, '%')
    const metadataRequest: MetadataRequestBody = [
      {
        format: "string",
        isText: true,
        key: "title",
        operator: "ILIKE",
        value: [formatedInput],
      },
      {
        format: "string",
        isText: true,
        key: "alternateSearchTerms",
        operator: "ILIKE",
        value: [formatedInput],
      },
      {
        format: "array",
        isText: false,
        key: "status",
        operator: "=",
        value: ["Published"],
      },
    ];

   

    const metadataSearchBody = {
      metadata: metadataRequest,
      metadataTemplate: "proagportalmetadata",
      fields: [
        "title",
        "alternateSearchTerms",
      ],
      offset: 0,
      limit: 60,
    };

    const response = await apiGateway.post(
      metadataSearchUrl,
      Document,
      metadataSearchBody
    )

    return response as MetadataDocumentResponse

  } else {
    let mdFilter = {};
    let isMetadataRequested = false;
    let content_type = "file_content";

    if (searchResultRequest.documentSearchType) {

      if(searchResultRequest.documentSearchType == "advancedSearch") {

        const mdFilter:any = {
          status: ["Published"]
        };
         
        if(searchResultRequest.metadataValues && searchResultRequest.metadataValues.length){
          searchResultRequest.metadataValues.map((v: any) => {
            const key = v.key
            mdFilter[key] = v.value
          });
        }
    
        const newSearchUrl = `categories/proagportal/search?offset=${
          searchResultRequest.offset
        }&isMetadataRequested=${true}&limit=20&template=proagportalmetadata&sort=modified_at&sort_direction=desc&mdFilters=${encodeURIComponent(
          JSON.stringify(mdFilter)
        )}`;

        const newResponse = await apiGateway.get(newSearchUrl, Document);
        return newResponse as MetadataDocumentResponse

      } else if (searchResultRequest.documentSearchType == "Announcements") {
        
        mdFilter = {
          status: ["Published"],
          contentType: ["Announcement News"],
        }

        content_type = "name"

      } else if (searchResultRequest.documentSearchType == "Documents") {

        const mdContentTypes = [
          "Form Letters",
          "Forms",
          "Manuals Tips Instructions",
          "Processes TOPE",
          "Release Notes",
          "Systems",
          "Templates",
          "Worksheets",
        ];
        mdFilter = {
          status: ["Published"],
          contentType: mdContentTypes,
        }

        content_type = "name"

      } else if (searchResultRequest.documentSearchType == "basicSearch") {

        content_type = "name"

        mdFilter = {
          status: ["Published"],
        }
      }

    } else {
      mdFilter = {
        status: ["Published"],
      };
    }
    const documentSearchUrl = `categories/proagportal/search?query=${
      searchResultRequest.searchText
    }&offset=${
      searchResultRequest.offset
    }&isMetadataRequested=${isMetadataRequested}&limit=20&template=proagportalmetadata&sort=modified_at&sort_direction=desc&contentTypes=${content_type}&mdFilters=${encodeURIComponent(
      JSON.stringify(mdFilter)
    )}`;

    const allTextResponse = await apiGateway.get(documentSearchUrl, Document);

    return allTextResponse as MetadataDocumentResponse;
  }
};

export const api = {
  getMetadataTemplate,
  getMetadataSearchResults,
};
