import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { api } from './api';

export const useEventAttachmentsQuery = (eventId: any, getAttachments: boolean) => {
  const apiGateway = useApiGateway();
  const { data, isFetching } = useQuery(
    [getAttachments],
    async () =>    
         await api.getEventAttachments(apiGateway, eventId),
    {      
      onSuccess: (data) => {
        if (!data) toast.info('You do not have any Event Attachments.');        
      },
        onError: (error) => {
          toast.error('An error has occurred with your request. Please try again or contact your sales representative. Error message:' + error)
        },
        }
  );

  return {
    eventAttachments: data,
    eventAttachmentsLoading: isFetching,  
  };
};
