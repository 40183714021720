import moment from 'moment';
import 'moment-timezone';

/**
 * moment.tz.guess() is providing system timezone in old timezone format.
 * so maintaing a map with oldTimezone name to current names. 
 * This is required to display the system timezone in timezone drop down list
 * ref - https://github.com/eggert/tz/blob/main/backward
 */
const oldTimezonetoNewTimeZoneMapping = new Map<String, String>();
oldTimezonetoNewTimeZoneMapping.set("america/chicago", "us/central");
oldTimezonetoNewTimeZoneMapping.set("americalLos_angeles", "us/pacific");
oldTimezonetoNewTimeZoneMapping.set("america/denver", "us/mountain");
oldTimezonetoNewTimeZoneMapping.set("america/new_york", "us/eastern");
oldTimezonetoNewTimeZoneMapping.set("pacific/honolulu", "us/hawaii");
oldTimezonetoNewTimeZoneMapping.set("america/anchorage", "us/alaska");

const allZones = moment.tz.names()
allZones.unshift('clear');
const requiredTimeZones = allZones.filter((timeZone: string) => timeZone.toLowerCase().includes("us/pacific") || timeZone.toLowerCase().includes("us/mountain") || timeZone.toLowerCase().includes("us/central") || timeZone.toLowerCase().includes("us/eastern") || timeZone.toLowerCase().includes("us/hawaii") || timeZone.toLowerCase().includes("us/alaska"));

interface TimezoneSelectProps {
  title?: string,
  defaultTZ: string,
  timezone: string,
  setTimezone: (timezone: string) => void
}

export default function TimezoneSelect({
  title,
  defaultTZ = moment.tz.guess(),
  timezone,
  setTimezone,
}: TimezoneSelectProps) {
  const onChange = (event: any) => {
    const { target: { value } } = event;
    setTimezone(value ? value : defaultTZ)
  }

  const userTimezone = oldTimezonetoNewTimeZoneMapping.get(defaultTZ.toLowerCase());

  return (
    <div>
      <div className="timezone-select">
        {title ? <strong style={{ marginBottom: 10 }}>{title}</strong> : null}
        <label>Select a Timezone: </label>{' '}
        <select
          className="form-control"
          style={{ width: 200, display: 'inline-block' }}
          onChange={onChange}
        >
          {requiredTimeZones.map((c, idx) => (
            <option key={idx} value={c !== 'clear' ? c : ''} selected={c.toLowerCase() == userTimezone || c.toLowerCase() == defaultTZ.toLowerCase()}>
              {c}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
