interface RoutePaths {
  login: string;
  home: string;
  calendar: string;
  searchResults: string;
  admin: string;
  links: string;
  employeeSearch: string;
  eventDetail: string;
  contentPreview: string;
  fileUpload: string;
}

export const routePaths: RoutePaths = {
  login: '/login/callback',
  home: '/home',
  calendar: '/calendar',
  searchResults: '/searchResults',
  admin: '/admin',
  links: '/links',
  employeeSearch: '/employeeSearch',
  eventDetail: '/eventDetail',
  contentPreview: '/contentPreview',
  fileUpload: '/fileUpload'
};
