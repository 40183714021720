import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import style from "views/search/component/Search.module.scss"

interface ReactSelectProps {
  metadataKey: string;
  selectedValues: any;
  selectedValuesLabelValue: any;
  optionsData: any;
  customComponents: any;
  customStyles: any;
  results: any;
  clearAll: boolean;
  onChange?: (newValue: any, actionMeta: any) => void;
}

export const ReactSelectComponent: React.FC<ReactSelectProps> = ({
  metadataKey,
  selectedValues,
  selectedValuesLabelValue,
  optionsData,
  customComponents,
  customStyles,
  results,
  clearAll,
  onChange,
}) => {
  const [selectKey, setKey] = useState(0);

  const formatOptions = (category: any) => {
    const commodities = optionsData?.fields?.find((index: any) => {
      return index.key === category;
    });

    const metadataOptions = commodities?.options.map((option: any) => {
      return {
        label: option.key,
        value: option.key,
      };
    });

    return metadataOptions;
  };

  const defaultValues = (fieldKey: any) => {
    return selectedValuesLabelValue.find((x: any) => x.fieldKey === fieldKey)
      ?.selectedValues;
  };

  const getDisplayName = (fieldKey: any) => {
    const displayNameField = optionsData?.fields?.find(
      (x: any) => x.key == fieldKey
    );
    return displayNameField ? displayNameField.displayName : "";
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    if (onChange) {
      onChange(newValue, actionMeta);
    }
  };

  useEffect(() => {
    setKey(Math.random());
  }, [results, clearAll]);

  return (
    <>
    <div
        title={
          selectedValues.some((value: any) => value.key === metadataKey)
            ? selectedValues
                .find((value: any) => value.key === metadataKey)
                ?.value.join(", ")
            : getDisplayName(metadataKey)
        }
      >
        <div className={style.dropdownLabel}>{getDisplayName(metadataKey)}</div>
      <Select
          key={selectKey}
          options={formatOptions(metadataKey)}
          isMulti
          className="mb-2"
          styles={customStyles}
          isSearchable={true}
          closeMenuOnSelect={false}
          isClearable
          defaultValue={defaultValues(metadataKey)}
          name={metadataKey}
          onChange={handleChange}
          components={{
            MultiValueContainer: customComponents.MultiValueContainer,
            Option: customComponents.Option,
            DropdownIndicator: customComponents.DropdownIndicator,
          }}
          hideSelectedOptions={false}
          tabIndex={selectKey}
          tabSelectsValue={false}
        />
        </div>
    </>
  );
};