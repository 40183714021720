import { Link } from "router6";
import { useUpcomingEventsQuery } from "views/home/api";
import style from "views/home/HomePage.module.scss";
import { useMemo, useContext } from "react";
import { UseRolesContext } from "app/context";
import { NoDataComponent } from "components/exceptions/NoDataComponent";
import { LoadingSpinner } from "components/LoadingSpinner";

const roleFilter = {
  "Internal": ['Insurance', 'Agent Training', 'Sales', 'Claims', 'Employee'],
  "External": ['Insurance', 'Agent Training']
}

export const UpcomingEventsComponent = () => {
  const { role, isRoleLoading } = useContext(UseRolesContext);
  const { activeEvent, isLoading } = useUpcomingEventsQuery(role);

  const events = useMemo(() => {
    const todayDate = new Date();
    const filteredRoleData = activeEvent?.value?.filter((event: any) => {
      if (role?.toUpperCase() === "INTERNAL") {
        if (event.categories.some((category: string) => { return roleFilter.Internal.includes(category) })) {
          const eventDate = convertDateToLocalDate(
            new Date(event["start"]["dateTime"])
          );
          return +eventDate > +todayDate;
        }
      }
      else if (role?.toUpperCase() === "EXTERNAL") {
        if (event.categories.some((category: string) => { return roleFilter.External.includes(category) })) {
          const eventDate = convertDateToLocalDate(
            new Date(event["start"]["dateTime"])
          );
          return +eventDate > +todayDate;
        }
      }
      else {
        const eventDate = convertDateToLocalDate(
          new Date(event["start"]["dateTime"])
        );
        return +eventDate > +todayDate;
      }
    }).slice(0,5);
    return filteredRoleData;
  }, [activeEvent]);

  //Sorting Events - closest event to further event 
  if (events && events.length > 0) {
    events.sort((a: any, b: any) => {
      if (b["start"]["dateTime"] > a["start"]["dateTime"]) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  function convertDateToLocalDate(date: Date) {
    const showTime = date.getHours()
        + ':' + date.getMinutes()
        + ":" + date.getSeconds();
        if(showTime !== "0:0:0"){
          var newDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000
          );
          return newDate;
        }    
    return date;
  }

  return (
    <>
      <div className={style.sectionTable}>
        <div className={style.sectionHeading}>Upcoming Events</div>
        {!isLoading ? (
          <div className={style.sectionBody}>
            {events && events?.length ? (
              events?.map((event: any) => (
                <div key={event["id"]} className={style.sectionRow}>
                  <Link
                    to={`/eventDetail?eventId=${event["id"]}`}
                    title={event["subject"]}
                    className={style.documentTitle}
                  >
                    {" "}
                    {event["subject"]}{" "}
                  </Link>
                  <div className={style.documentDate}>
                    {" "}
                    {new Date(event["start"]["dateTime"]).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}{" "}
                  </div>
                </div>
              ))
            ) : (
              <NoDataComponent
                noDataMessage={"There are no upcoming events."}
              ></NoDataComponent>
            )}
          </div>
        ) : (
          <div className={style.loadingContainer}>
            <LoadingSpinner message="Loading Results...Please Wait" />
          </div>
        )}
        {events && (
          <div className={style.viewAll}>
            <a href="/calendar">View All</a>
          </div>
        )}
      </div>
    </>
  );
};
