import { Spinner } from 'react-bootstrap';
interface LoadingSpinnerProps {
  message?: string;
}
export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({message}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Spinner variant='primary' animation='border' />
      {message && (
      <div style={{opacity: '3'}} className='mt-3'>{message}</div>

      )}
    </div>
  );
};
