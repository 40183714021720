import { RoleName } from "app/apiGateway/useRole";
import {
  ContentDataObject,
  HeaderObject,
  LinkObject,
} from "views/admin/api/api";

export const getLinksService = (
  contentDataObject: ContentDataObject | undefined,
  role: RoleName | undefined
) => {
  if (contentDataObject) {
    const contentData = contentDataObject.data;

    const headerList: HeaderObject[] = JSON.parse(contentData);

    const sortedHeaders = headerList?.map((x: any) => {
      return {
        headerTitle: x.headerTitle,
        metadata: x.metadata,
        links: x.links?.filter((link: any) =>
        link?.metadata?.authorization_role.includes(role)
       ).sort((a: any, b: any) =>
       a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
     ),
      };
    }).sort((a, b) => (a.headerTitle.toLowerCase() > b.headerTitle.toLowerCase() ? 1 : -1));

    const filteredLinks: HeaderObject[] = sortedHeaders?.filter(
        (header: any) => header?.metadata?.authorization_role.includes(role)
      );

    return filteredLinks != undefined ? filteredLinks : [];
  }
};
