import { SearchResultTableRow } from "../type";
import { FileEntityResponse } from "../api/api";
import _ from "lodash";

export const mapSearchResultResponseForTable = (
  respData : FileEntityResponse | undefined
  ): SearchResultTableRow[] => {
    const mappedRows : SearchResultTableRow[] = _.map(respData, (tableRow) => {
      const {
        description,
        modifiedAt,
        name,
        id,
        metadata
      } = tableRow; 

      let fileTitleName = name?.slice(0, name.lastIndexOf('.')).trim() ?? ""
      let alternateSearchTerms = ""

      if(metadata && metadata.metadataFields){
        if(metadata.metadataFields["title"]) fileTitleName = metadata.metadataFields["title"].toString()
        if(metadata.metadataFields["alternateSearchTerms"]) alternateSearchTerms = metadata.metadataFields["alternateSearchTerms"].toString()
      } 

      //TODO: handle regex trimming of dates off of filename
      
      return {
        id,
        fileName: name?.slice(0, name.lastIndexOf('.')),
        type: name?.slice(name.lastIndexOf('.'), name.length),
        description: description,
        dateModified: new Date(modifiedAt).toLocaleDateString(),
        title: fileTitleName !== "" ? fileTitleName.trim() : fileTitleName,
        alternateSearchTerms: alternateSearchTerms !== "" ? alternateSearchTerms.trim() : alternateSearchTerms,
      };
    });
    const sortedResult = _.orderBy(mappedRows, ['modified_at'], ['desc']);
    return sortedResult;
  };