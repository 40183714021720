import { Helmet } from "react-helmet";
import style from "views/contentPreview/ContentPreview.module.scss";
import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import blankLogo from '../../images/blank_logo.png';

//Preview window
export const ContentPreview = () => {

    //Get fileid from Querystring
    const fileId = new URLSearchParams(window.location.search).get("fileId") ?? "";    
    if (fileId !== undefined)
    {
        localStorage.setItem("fileId", fileId);
    }
    //Get Session Id from OktaAuth token
    const { authState } = useOktaAuth();
    const [sessionId, setSessionId] = useState<string>();

    //API URL
    let apiUrl = (window as any).globalConfig.ApiGateway_URL;
    if(localStorage.getItem("APIGateway") === null)
    {
     localStorage.setItem("APIGateway", apiUrl);
    }
    let authorization: any;
    //set authorization for initial request
    if (sessionId !== undefined) {
      authorization = sessionId;      
    } else {
      authorization = authState?.accessToken?.accessToken;     
    }
    localStorage.setItem("accessToken", authorization);
    //Getting Balnk Logo to display on UI
    if(localStorage.getItem("blank_logo") === null){
      localStorage.setItem("blank_logo", blankLogo);
    }

    return (
    <>
    <Helmet>
        <link
          href="https://cdn01.boxcdn.net/platform/preview/2.93.0/en-US/preview.css"
          rel="stylesheet"
          type="text/css">          
        </link>
        <script id="preview" src="https://cdn01.boxcdn.net/platform/preview/2.93.0/en-US/preview.js"></script>
        <script  src="https://cdn01.boxcdn.net/polyfills/core-js/2.5.3/core.min.js"></script>

        <script type="text/javascript" >{`
        var oB=document.getElementById('preview');
        var fileId = localStorage.getItem('fileId');
        
        var addExtraHeaders = (config ) => {
            config.headers['ApplicationId'] = 'document';       
            return config;
            }; 

        oB.onload=function(){         
          var accessToken = localStorage.getItem('accessToken');
          var id = fileId;
          var preview = new Box.Preview();
          preview.show(
              id, 
              accessToken,
              {
                apiHost: localStorage.getItem('APIGateway') + "/document-binary/categories/proagportal", 
                requestInterceptor: addExtraHeaders, 
                container: "#proag-doc", 
                showDownload: true,
                logoUrl: localStorage.getItem('blank_logo'),
              }
          );
      }

`}</script>
      </Helmet>
      <div className={style.ContentPreview} id="proag-doc">
          Content Preview Loading......
        </div>
    </>
  );
};