import { useQuery } from "@tanstack/react-query";
import { useApiGateway } from "app/apiGateway";
import { useErrorBoundary } from "react-error-boundary";
import { api } from "./api";
import { toast } from "react-toastify";

export const useSystemLaunchQuery = (loadData:any) => {
    const apiGateway = useApiGateway();
    const { showBoundary } = useErrorBoundary();
  
    const { data, isFetching, isSuccess } = useQuery(
      ['systemLaunchQuery', loadData],
      async () =>    
           await api.getSystemLaunchLinks(apiGateway),
      {
        onSuccess: (data) => {
          if (!data) toast.info('You do not have any links.');
        },
        // source: https://www.npmjs.com/package/react-error-boundary
        // Errors thrown in event handlers, or after async code has run, will not be caught.
        // hook showBoundary to pass those errors to the nearest error boundary
        onError: (error: any) => {showBoundary(error)},
      }
    );
  
    return {
      data,
      isLoading: isFetching, 
      isSuccess 
    };
  };