import { Routes, Route, Navigate, Outlet } from 'router6';
import { LoginCallback } from '@okta/okta-react';

import { routePaths } from './routes';
import { SecureRoutesLayoutRoles } from './SecureRoutesLayoutRoles';
import { Page } from 'components/navigation';

import { HomePage } from 'views/home';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import { SearchResults } from 'views/search/SearchResults';
import { Links } from 'views/links/Links';
import { EmployeeSearch } from 'views/employeeSearch/EmployeeSearch';
import { EventCalendar } from 'views/Calendar/EventCalendar';
import { EventDetail } from 'views/Calendar';
import { ContentPreview } from 'views/contentPreview/ContentPreview';
import { RoleError } from 'components/RoleError';
import { awsRumErrorHandler, ErrorFallback } from 'app/errorBoundary';
import { ErrorBoundary } from 'react-error-boundary';
import { AdminView } from 'views/admin/AdminView';
import { FileUpload } from 'views/fileUpload/FileUpload';


export const RootRouter = () => {
  return (
    <Routes>
      <Route
        path={routePaths.login}
        element={<LoginCallback  errorComponent={RoleError} loadingElement={<LoadingSpinner />} />}
      />

      <Route path={routePaths.home} element={<SecureRoutesLayoutRoles/>} >
        <Route index element={<Page content={<HomePage />} />} />
      </Route>

      <Route path={routePaths.admin} element={<SecureRoutesLayoutRoles rolesPermission='Administrator'/>} >
        <Route index element={<Page content={<AdminView />} />} />
      </Route>

      <Route path={routePaths.calendar} element={<SecureRoutesLayoutRoles/>} >
        <Route index element={<Page content={<EventCalendar />} />} />
      </Route>

      <Route path={routePaths.employeeSearch} element={<SecureRoutesLayoutRoles/>} >
        <Route index element={<Page content={<EmployeeSearch />} />} />
      </Route>

      <Route path={routePaths.fileUpload} element={<SecureRoutesLayoutRoles/>} >
        <Route index element={<Page content={<FileUpload />} />} />
      </Route>

      <Route path={routePaths.links} element={<SecureRoutesLayoutRoles/>} >
        <Route index element={<Page content={<Links />} />} />
      </Route>

      <Route path={routePaths.searchResults} element={<SecureRoutesLayoutRoles/>}>
        <Route index element={<Page content={
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error, componentStack) => {awsRumErrorHandler(error, componentStack)}} >
        <SearchResults />
        </ErrorBoundary>
        } />} />
      </Route>
      
      <Route path={routePaths.eventDetail} element={<SecureRoutesLayoutRoles />}>
        <Route index element={<Page content={<EventDetail />} />} />
      </Route>
      
      <Route path={routePaths.contentPreview} element={<SecureRoutesLayoutRoles />}>
        <Route index element={<Page content={<ContentPreview />}/>}/>
      </Route>

      <Route path='*' element={<Navigate to={routePaths.home} />} />
    </Routes>
  );
};
