import { ColumnDef, 
    flexRender, 
    getCoreRowModel, 
    useReactTable, 
    getPaginationRowModel,
     PaginationState, 
     SortingState, 
     getSortedRowModel,
     getFilteredRowModel,
     } from "@tanstack/react-table";
import { Table } from "react-bootstrap";
import style from "./EmployeeTable.module.scss";
import { useEffect, useMemo, useState } from "react";
import {
    ColumnTextFilter,
    Pagination,
  } from 'components/tables';

interface EmployeeTableRow {
    givenName: string,
    surname: string,
    businessPhones: [],
    mail: string,
    jobTitle: string,
    department: string,
}

interface EmployeeTableProps {
    employeeData: EmployeeTableRow[];
    setSelectedEmployee: (employee: EmployeeTableRow | any, openModal: boolean) => void;
}

const paginationState = {
    pageIndex: 0,
    pageSize: 20,
  };

export const EmployeeTable = ({ employeeData, setSelectedEmployee }: EmployeeTableProps) => {
    const [pagination, setPagination] = useState<PaginationState>(paginationState);
    const [sorting, setSorting] = useState<SortingState>([]);
    
    const columns = useMemo(
        (): ColumnDef<EmployeeTableRow>[] => [
            {
                id: 'firstName',
                accessorFn: (row) => row.givenName,
                cell: (info) => info.getValue(),
                header: () => <span>First Name</span>,
            },
            {
                id: 'lastName',
                accessorFn: (row) => row.surname,
                cell: (info) => info.getValue(),
                header: () => <span>Last Name</span>,
            },
            {
                id: 'businessPhone',
                accessorFn: (row) => row.businessPhones,
                cell: (info) => info.getValue(),
                header: () => <span>Phone</span>,
            },
            {
                id: 'email',
                accessorFn: (row) => row.mail,
                cell: (info) => info.getValue(),
                header: () => <span>Email</span>,
            },
            {
                id: 'jobTitle',
                accessorFn: (row) => row.jobTitle,
                cell: (info) => info.getValue(),
                header: () => <span>Job Title</span>,
            },
            {
                id: 'department',
                accessorFn: (row) => row.department,
                cell: (info) => info.getValue(),
                header: () => <span>Department</span>,
            },
        ],
        []
    );

    const totalResults = employeeData?.length

    const pages = totalResults ? Math.ceil(totalResults / pagination.pageSize) : 0

    const table = useReactTable<any>({
        columns,
        state: {  pagination, sorting },
        data: employeeData,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        sortDescFirst: false,
        getFilteredRowModel: getFilteredRowModel(),
        pageCount: pages,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        autoResetPageIndex: true,
        onPaginationChange: setPagination,
    });

    useEffect(() => {
        if(!employeeData?.length){
            setSelectedEmployee(null, false);
        }
    }, [employeeData])

    const handleNameClick = (row: any) => {
        setSelectedEmployee(row?.original, true);
    }

    return (
        <div className={style.maincontainer}>
            <Pagination data={employeeData} table={table} total={totalResults}/>
            <Table striped hover responsive className={style.tableContainer}>
                <thead>
                    {(() => {
                        const { headers } = table.getHeaderGroups()[0];
                        return (
                            <tr>
                                <th>
                                    <div className={style.tableHeader}>
                                    <ColumnTextFilter header={headers[0]} />
                                    </div>
                                </th>
                                <th>
                                    <div className={style.tableHeader}>
                                    <ColumnTextFilter header={headers[1]} />
                                    </div>
                                </th>
                                <th>
                                    <div className={style.tableHeader}>
                                        {flexRender(headers[2].column.columnDef.header, headers[2].getContext())}
                                    </div>
                                </th>
                                <th>
                                    <div className={style.tableHeader}>
                                        {flexRender(headers[3].column.columnDef.header, headers[3].getContext())}
                                    </div>
                                </th>
                                <th>
                                    <div className={style.tableHeader}>
                                    <ColumnTextFilter header={headers[4]} />
                                    </div>
                                </th>
                                <th>
                                    <div className={style.tableHeader}>
                                    <ColumnTextFilter header={headers[5]} />
                                    </div>
                                </th>
                            </tr>
                        );
                    })
                        ()
                    }
                </thead>
                {
                    employeeData?.length ?
                        (
                            <tbody>
                                {table.getRowModel().rows.map((row) => {
                                    const cells = row.getVisibleCells();
                                    return (
                                        <tr key={row.id}>
                                            <td>
                                                <div className="d-flex flex-column">
                                                <a onClick={() => handleNameClick(row)} className={style.employeeName}>
                                                    {flexRender(
                                                        cells[0].column.columnDef.cell,
                                                        cells[0].getContext()
                                                    )}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-column">
                                                <a onClick={() => handleNameClick(row)} className={style.employeeName}>
                                                    {flexRender(
                                                        cells[1].column.columnDef.cell,
                                                        cells[1].getContext()
                                                    )}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-column">
                                                    <a className={style.infoLink} href={`tel:${row["original"]["businessPhones"]}`}>
                                                        {flexRender(
                                                            cells[2].column.columnDef.cell,
                                                            cells[2].getContext()
                                                        )}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-column">
                                                    <a className={style.infoLink} href={`mailto:${row["original"]["mail"]}`}>
                                                        {flexRender(
                                                            cells[3].column.columnDef.cell,
                                                            cells[3].getContext()
                                                        )}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-column">
                                                    {flexRender(
                                                        cells[4].column.columnDef.cell,
                                                        cells[4].getContext()
                                                    )}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-column">
                                                    {flexRender(
                                                        cells[5].column.columnDef.cell,
                                                        cells[5].getContext()
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        ) :
                        (
                            <tbody>
                                <tr>
                                    <td colSpan={6} align="center">
                                    No Data Found
                                    </td>
                                </tr>
                               
                            </tbody>
                        )
                }
            </Table>
        </div>
    );
}