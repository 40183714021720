import {
  ContentDataObject,
  ContentObject,
  HeaderObject,
  LinkObject,
} from "../api/api";

export const putAdminContentService = (
  adminContent: any,
  data: any,
  isDelete: boolean,
  username: string
) => {

  let dataArray = adminContent?.headers;
  let duplicates = false;
  let contentObject: ContentDataObject = {
    contentType: "",
    contentId: "",
    createdBy: "",
    createdDate: "",
    data: "",
    modifiedBy: "",
    modifiedDate: "",
  };

  if (isDelete) {
    const parentDeleteIndex = Number(data.parentId.split("-")[1]);
    const childDeleteIndex = Number(data.childId.split("-")[1]);

    if (data.childId === "") {
      if (dataArray) {
        dataArray?.splice(parentDeleteIndex, 1);
      }
    } else {
      if (dataArray) {
        dataArray[parentDeleteIndex]["links"].splice(childDeleteIndex, 1);
      }
    }
  } else {
    const formatRoles = data.roles.map((role: any) => {
      return role;
    });
    // add Header Group
    if (data.childIds === "0" && !Boolean(data.parentIds)) {
      const editParentIndex = Number(data.parentIds.split("-")[1]);

      const groupEntry: HeaderObject = {
        headerTitle: data.parentName,
        metadata: {
          authorization_role: formatRoles,
          modifiedBy: "",
          modifiedDate: "",
          createdBy: username,
          createdDate: new Date().toLocaleString(),
        },
        links: [] as LinkObject[],
      };

      duplicates = adminContent?.headers.some(
        (el: any) =>
          el.headerTitle.toLowerCase() === groupEntry.headerTitle.toLowerCase()
      );
      if(!duplicates){
        dataArray = [...dataArray, groupEntry];

      }
    }
    // EDIT header group
    else if(data.childIds === "0" && Boolean(data.parentIds)){
      const editParentIndex = Number(data.parentIds.split("-")[1]);
      const currentEditLinksArray = dataArray

      const editGroupEntry: HeaderObject = {
        headerTitle: data.parentName,
        metadata: {
          authorization_role: formatRoles,
          modifiedBy: "",
          modifiedDate: "",
          createdBy: username,
          createdDate: new Date().toLocaleString(),
        },
        links: [] as LinkObject[],
      };

      let exceptCurrentPopUp = [
        ...currentEditLinksArray.slice(0, editParentIndex),
        ...currentEditLinksArray.slice(editParentIndex + 1),
      ];

      duplicates = exceptCurrentPopUp.some(
        (el: any) =>
          el.headerTitle.toLowerCase() === editGroupEntry.headerTitle.toLowerCase() 
      );

      if (!duplicates) {
        dataArray[editParentIndex].headerTitle = data.parentName;
          dataArray[editParentIndex].metadata = {
            authorization_role: formatRoles,
            modifiedBy: username,
            modifiedDate: new Date().toLocaleString(),
            createdBy: data.createdBy,
            createdDate: data.createdDate,
          };
          dataArray[editParentIndex].links.forEach((element:any,index:number) => {
            const eachLinkrole =dataArray[editParentIndex].links[index].metadata.authorization_role;
            dataArray[editParentIndex].links[index].metadata.authorization_role=formatRoles
          });
      }

    }
    // ADD sublink
    else if (Boolean(data.parentIds) && !Boolean(data.childIds)) {
      const parentIndex = Number(data.parentIds.split("-")[1]);
      const currentLinksArray = dataArray[parentIndex].links;

      const newChildEntry: LinkObject = {
        title: data.childName,
        metadata: {
          authorization_role: formatRoles,
          modifiedBy: "",
          modifiedDate: "",
          createdBy: username,
          createdDate: new Date().toLocaleString(),
        },
        link: data.childLink,
      };

      // grab current data object and add this object to it, then stringify

      duplicates = currentLinksArray.some(
        (el: any) =>
          el.title.toLowerCase() === newChildEntry.title.toLowerCase() ||
          el.link.toLowerCase() === newChildEntry.link.toLowerCase()
      );
      if (!duplicates) {
        let current = currentLinksArray ?? [];
        let updatedData = [...current, newChildEntry];

        dataArray[parentIndex].links = updatedData;
      }
    }
    // EDIT sublink
    else {
      const parentEditIndex = Number(data.parentIds.split("-")[1]);
      const childEditIndex = Number(data.childIds.split("-")[1]);
      const currentEditLinksArray = dataArray[parentEditIndex].links;

      const editChildEntry: LinkObject = {
        title: data.childName,
        metadata: {
          authorization_role: formatRoles,
          modifiedBy: username,
          modifiedDate: new Date().toLocaleString(),
          createdBy: data.createdBy,
          createdDate: data.createdDate,
        },
        link: data.childLink,
      };

      let exceptCurrentPopUp = [
        ...currentEditLinksArray.slice(0, childEditIndex),
        ...currentEditLinksArray.slice(childEditIndex + 1),
      ];
      duplicates = exceptCurrentPopUp.some(
        (el: any) =>
          el.title.toLowerCase() === editChildEntry.title.toLowerCase() ||
          el.link.toLowerCase() === editChildEntry.link.toLowerCase()
      );
      if (!duplicates) {
        dataArray[parentEditIndex].links[childEditIndex] = editChildEntry;
      }
    }
  }

  const stringData = encodeURI(JSON.stringify(dataArray))

  contentObject = {
    contentId: adminContent.contentId, // "urn:proag:portal:links",
    contentType: adminContent.contentType, //"PortalLinks",
    data: stringData,
    createdBy: "",
    createdDate: new Date().toISOString(),
    modifiedBy: "",
    modifiedDate: new Date().toISOString(),
  };

  return {
    duplicates: duplicates,
    saveObject: contentObject,
  };
};