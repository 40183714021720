import { useEffect } from 'react';
import { BrowserRouter } from 'router6';

import { RootRouter } from './routing/RootRouter';
import { OktaAuthProvider } from './okta/OktaAuthProvider';
import { ReactQueryProvider } from './utils/ReactQueryProvider';
import { useGoogleAnalytics } from './utils/useGoogleAnalytics';
import { ErrorFallback, awsRumErrorHandler } from "./errorBoundary";
import { ErrorBoundary } from 'react-error-boundary';
import '../global.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

export const App = () => {
	useGoogleAnalytics();

	return (
		<ReactQueryProvider>
			<ErrorBoundary FallbackComponent={ErrorFallback} onError={(error, componentStack) => {awsRumErrorHandler(error, componentStack)}} >
				<BrowserRouter>
					<OktaAuthProvider>
						<RootRouter />
					</OktaAuthProvider>
				</BrowserRouter>
			</ErrorBoundary>
		</ReactQueryProvider>
	);
};
