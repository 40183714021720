import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { api } from 'views/home/api/api';
import { useErrorBoundary } from 'react-error-boundary';
import { RoleName } from 'app/apiGateway/useRole';

export const useUpcomingEventsQuery = (role: RoleName | undefined) => {
  const apiGateway = useApiGateway();
  const { showBoundary } = useErrorBoundary();
  
  const { data, isFetching } = useQuery(
    ['activeEvent'],
    async () =>    
         await api.getUpcomingEvents(apiGateway, role),
    {      
      enabled: !!role,
      onSuccess: (data) => {
        if (!data.value) toast.info('You do not have any Upcoming Events.');
      },
      // source: https://www.npmjs.com/package/react-error-boundary
      // Errors thrown in event handlers, or after async code has run, will not be caught.
      // hook showBoundary to pass those errors to the nearest error boundary
      onError: (error: any) => {showBoundary(error)},
    }
  );

  return {
    activeEvent: data,
    isLoading: isFetching,  
  };
};
