import { Accordion} from "react-bootstrap";
import "./links.scss";
import { useLinksQuery } from "./api/useLinksQuery";
import { getLinksService } from "./Services/getLinksService";
import { UseRolesContext } from "app/context";
import { useContext} from "react";

export const Links = () => {
  const { role, isRoleLoading } = useContext(UseRolesContext);

  const { data, isLoading } = useLinksQuery();
  const adminLinks = getLinksService(data, role);
  return (
    <>
      <div className="links">
        <Accordion>
            {adminLinks?.map((header: any, parentindex: any) => {
              return (
                  <Accordion.Item eventKey={parentindex} key={parentindex}>
                    <Accordion.Header >
                      {header.headerTitle}                     
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="links-wrapper">
                        {header.links?.map((link: any, childindex: string) => {
                          
                            return (
                              <div key={childindex}>
                                <a
                                  key={`${link.title}-${childindex}`}
                                  href={link.link}
                                  target="_blank"
                                  rel="noopener"
                                >
                                  {link.title}
                                </a>
                              </div>
                            );
                        }
                        )}            
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
            })} 
        </Accordion>
      </div>    
    </>
  );
};

export default Links;
