import { useState } from 'react';
import style from "views/home/HomePage.module.scss";
import { useNavigate } from "router6";
import { Form, InputGroup, Row, Button } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

export const SearchBox = (props: any) => {
  const [searchText, setSearchText] = useState(props.searchInput);
  const navigator = useNavigate();
  const navigateToSearchResults = (e: any) => {
    e.preventDefault();
    navigator(`/searchResults?default=basicSearch&searchText=${searchText === undefined ? "" : searchText}`);
  }
  return (
    <Row>
      <Form onSubmit={navigateToSearchResults}>
        <InputGroup className={style.searchBox}>
          <Form.Control value={searchText} className={style.searchInput} onChange={(e: any)=>setSearchText(e.target.value)} />
          <InputGroup.Text className={style.searchIcon} >
            <Search size={18} onClick={navigateToSearchResults}/>
          </InputGroup.Text>
        </InputGroup>
      </Form>
    </Row>
  )
};
