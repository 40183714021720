import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { EmployeeSearchOption } from '../EmployeeSearchType';
import { api } from './api';

export interface EmployeeSearchOptionResult {
  employeeSearchOptions: EmployeeSearchOption[];
  isLoading: boolean;
}

export const useEmployeeSearchOptionsQuery = () : EmployeeSearchOptionResult => {
  const apiGateway = useApiGateway();

  const { data, isFetching } = useQuery(
    ['employeeSearchOptions'],
    async () =>    
         await api.getJobTitleOptions(apiGateway),
    {      
      onSuccess: (data) => {
        if (!data.value?.length) toast.info('You do not have any job title options.');
      },
        onError: (error) => toast.error('An error has occurred with your request. Please try again or contact your sales representative. Error message:' + error),
    }
  );
  let employeeSearchOptions;
  if(data?.value?.length){
    employeeSearchOptions = data.value
  }

  return {
    employeeSearchOptions,
    isLoading: isFetching,  
  };
};
