import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { api } from 'views/search/api';
import { useErrorBoundary } from 'react-error-boundary';

export const useMetadataSearchQuery = (_searchResultRequest: any) => {
  const apiGateway = useApiGateway();
  const { showBoundary } = useErrorBoundary();

  const { data, isFetching, refetch } = useQuery(
    [_searchResultRequest],
    async () =>     
         await api.getMetadataSearchResults(apiGateway, _searchResultRequest),
    {  
      onSuccess: (data) => {
        if (!data) toast.info('You do not have any results.');
      },
      // source: https://www.npmjs.com/package/react-error-boundary
      // Errors thrown in event handlers, or after async code has run, will not be caught.
      // hook showBoundary to pass those errors to the nearest error boundary
      onError: (error: any) => {
        showBoundary(error)
      },
      }
  );
  return {
    metadataResults : data,
    isSearchResultLoading: isFetching,
    refetchData: refetch,  
  };
};
