import { useState, useContext, useEffect } from "react";
import style from "./Calendar.module.scss";
import { CalendarComponent } from "./components/CalendarComponent";
import { NavigateCalendarComponent } from "./components/NavigateCalendarComponent";
import { UseRolesContext } from "app/context";
import { CalendarEventsRequest } from "./api/api";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback, awsRumErrorHandler } from "app/errorBoundary";
import { CalendarErrorFallback } from "./components/CalendarErrorFallback";

interface Calender {
  label: string;
  isCalenderActive: boolean;
  className: string;
  role?: string;
}

const ROLE_EXTERNAL = "External";

const colorMap: any = {
  Sales: {
    backgroundColor: "#5CA9E5",
    color: "black",
  },
  "Agent Training": {
    backgroundColor: "#52CE90",
    color: "black",
  },
  Employee: {
    backgroundColor: "#E8825D",
    color: "black",
  },
  Insurance: { backgroundColor: "#DC626D", color: "black" },
  Contacts: { backgroundColor: "#EE5FB7", color: "black" },
  Claims: {
    backgroundColor: "#FDEE65",
    color: "black",
  },
};

export const EventCalendar = () => {
  const allCalenders: Calender[] = [
    {
      label: "Sales",
      isCalenderActive: true,
      className: "sales",
    },
    {
      label: "Insurance",
      isCalenderActive: true,
      className: "insurance",
      role: ROLE_EXTERNAL,
    },
    {
      label: "Agent Training",
      isCalenderActive: true,
      className: "agent-training",
      role: ROLE_EXTERNAL,
    },
    {
      label: "Claims",
      isCalenderActive: true,
      className: "claims",
    },
    {
      label: "Employee",
      isCalenderActive: true,
      className: "employee",
    },
  ];

  const [calenders, setCalenders] = useState<Calender[]>([]);
  const [selectedItem, setSelectedItem] = useState<Calender>();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const request: CalendarEventsRequest = {
    selectedDate: selectedDate,
  };
  const [eventsRequest, setEventsRequest] = useState(request);

  const { role, isRoleLoading } = useContext(UseRolesContext);

  useEffect(() => {
    let filteredCalenders = allCalenders;
    if (role === ROLE_EXTERNAL) {
      filteredCalenders = allCalenders.filter((cal) => cal.role === role);
    }
    setCalenders(filteredCalenders);
  }, [role]);

  const handleOnLinkClick = (selectedItem: Calender) => {
    setSelectedItem(selectedItem);
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    const request: CalendarEventsRequest = {
      selectedDate: date,
    };
    setEventsRequest(request);
  };

  const renderCalenders = (role: any) => {
    return (
      <>
        <h4>ProAg Calender</h4>
        {calenders.map((calender, index) => {
          return (
            <>
              <div
                style={{
                  backgroundColor: colorMap[calender.label].backgroundColor,
                  color: colorMap[calender.label].color,
                }}
                className={style.CommonCalenders}
                key={`${calender.label}-${index}`}
              >
                <div
                  className={style.CalenderLabelLinks}
                  onClick={() => handleOnLinkClick(calender)}
                >
                  {calender.label}
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className={style.Calendar}>
      <ErrorBoundary FallbackComponent={CalendarErrorFallback} onError={(error, componentStack) => {awsRumErrorHandler(error, componentStack)}} >

        <div className={style.LeftPanel}>
          <div className={style.LeftContainer}>
            <NavigateCalendarComponent
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
            />
            {!selectedItem ? (
              <div className={style.CategoryPanel}>
                {role && renderCalenders(role)}
              </div>
            ) : null}
            {selectedItem && (
              <>
                <div
                  style={{
                    backgroundColor:
                      colorMap[selectedItem.label].backgroundColor,
                    color: colorMap[selectedItem.label].color,
                  }}
                  className={`${style[selectedItem.className]} ${
                    style.CommonCalenders
                  } `}
                >
                  <div className={style.CalenderLabelLinks}>
                    {selectedItem.label}
                  </div>
                </div>
                <div
                  className={style.ReturnLink}
                  onClick={() => {
                    setSelectedItem(undefined);
                  }}
                >
                  <span>Return to ProAg Calender</span>
                </div>
              </>
            )}
          </div>
        </div>

        <CalendarComponent
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
          selectedItem={selectedItem}
          role={role}
          eventsRequest={eventsRequest}
          //calendarEvents={calenderEvents}
          eventPropGetter={(event: any) => {
            let style = {};
            if (event.categories && event.categories.length) {
              if (colorMap[event.categories[0]]) {
                style = {
                  ...colorMap[event.categories[0]],
                };
              }
            }
            return { style };
          }}
        />
      </ErrorBoundary>
        
      </div>
    </>
  );
};
