import { useContext, useState } from "react";
import { Accordion, Alert, Badge, Button, Nav } from "react-bootstrap";
import { useSystemAlertsQuery } from "views/home/api/useSystemAlertsQuery";
import style from "views/home/HomePage.module.scss";
import { UseRolesContext } from "app/context";
import { Link } from "router6";

export const SystemAlertsComponent = () => {
  const { role, isRoleLoading } = useContext(UseRolesContext);
  const { activeAlert, areactiveAlertLoading } = useSystemAlertsQuery(role);

  const alerts = activeAlert?.value;

  return (
    <>
      {!isRoleLoading && alerts && alerts.length > 0 && (
        <div>
          {alerts?.map((alert: any) => (
            <div className={style.alertDetails} key={alert["id"]}>
              <Link
                to={`/eventDetail?eventId=${alert["id"]}`}
                title={alert["subject"]}
                className={style.documentTitle}
              >
                {" "}
                {alert["subject"]}{" "}
              </Link>
              {alert["location"]["displayName"] && (
                <>
                  <div className={style.alertElements}> {"|"} </div>
                  <div className={style.alertElements}>
                    {"  "}
                    {alert["location"]["displayName"]}{" "}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
