import { Button, Modal } from "react-bootstrap";
import style from "views/employeeSearch/EmployeeSearch.module.scss";
import noPicture from "images/NoPicture_AdobeStock_346839683_150x150.jpeg";

export const EmployeeDetailModal = (props: any) => {
  const photoSrc = `data:image/png;base64,${props.profilePhoto}`;
  const noImage = props.profilePhoto && props.profilePhoto == "no image";

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className={style.employeeModal}>
          <div className={style.employeeCard}>
            {noImage ? (
              <img className={style.profilePhoto} src={noPicture}></img>
            ) : (
              <img className={style.profilePhoto} src={photoSrc}></img>
            )}
            <p className={style.employeeCardText}>{`${props.name}`}</p>
            <p className={style.employeeCardText}>{props.job}</p>
            <p className={style.employeeCardText}>{props.dept}</p>
            <p className={style.employeeCardText}>
              <a href={`tel:${props.phone}`}>{props.phone}</a>
            </p>
            <p className={style.employeeCardText}>
              <a href={`mailto:${props.email}`}>{props.email}</a>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
