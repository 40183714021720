import { Table } from '@tanstack/react-table';
import { Pagination as RBPagination } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

import style from './Pagination.module.scss';

interface PaginationProps {
  data: any[];
  table: Table<any>;
  total: any;
}

export const Pagination: React.FC<PaginationProps> = ({ data, table, total }) => {
  let isOutOfRange: boolean | undefined;
  const pageNumbers = [...new Array(table.getPageCount())].map((_, index) => {
    const pageNumber = index + 1;
    const currentPage = table.getState().pagination.pageIndex + 1;

    const isFirst = pageNumber == 1;
    const isLast = pageNumber == table.getPageCount();
    const isWithinTwoNumbers = Math.abs(pageNumber - currentPage) <= 2;

    if (isFirst || isLast || isWithinTwoNumbers) {
      isOutOfRange = false;

      return (
        <RBPagination.Item
          key={pageNumber}
          active={pageNumber == currentPage}
          onClick={() => table.setPageIndex(pageNumber - 1)}
        >
          {pageNumber}
        </RBPagination.Item>
      );
    }

    if (!isOutOfRange) {
      isOutOfRange = true;
      return <RBPagination.Ellipsis key={pageNumber} className='muted' />;
    }

    return;
  });

  return (
    <div className={style.paginationContainer}>
      <div className={style.totalItems}>Total {total} items</div>
      <RBPagination className={style.customPagination}>
        <RBPagination.Prev
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft style={{ height: '12px', width: '12px' }} />
        </RBPagination.Prev>
        {pageNumbers}
        <RBPagination.Next
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight style={{ height: '12px', width: '12px' }} />
        </RBPagination.Next>
      </RBPagination>
    </div>
  );
};
