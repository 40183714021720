import style from './NoDataComponent.module.scss'
interface NoDataComponentProps {
    noDataMessage: string;
  }
export const NoDataComponent: React.FC<NoDataComponentProps> = ({noDataMessage}) => {

  return (
    <>
    <div className={style.container}>
        <div className={style.message}>
            {noDataMessage}
        </div>
    </div>
    </>
  );
};