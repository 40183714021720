import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { MetadataOptionEntity, api } from './api';

export const useMetadataOptionsQuery = () => {
  const apiGateway = useApiGateway();

  const { data, isFetching } = useQuery(
    ['metadataOptions'],
    async () =>    
         await api.getMetadataTemplate(apiGateway),
    {     
      onSuccess: (data) => {
        if (!data) toast.info('No options for provided template');
      },
        onError: (error) => toast.error('An error has occurred with your request. Please try again or contact your sales representative. Error message:' + error),
    }
  );

  return {
    data,
    areOptionsLoading: isFetching,  
  };
};
