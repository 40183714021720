import { useState, useEffect } from 'react';
import { Header, flexRender } from '@tanstack/react-table';
import { Form, InputGroup } from 'react-bootstrap';
import {
  Search,
  ChevronUp,
  ChevronDown,
  ChevronExpand,
  X,
} from 'react-bootstrap-icons';

import style from './ColumnTextFilter.module.scss';

export const ColumnTextFilter = ({
  header,
  isInCoverageTable,
  resetFilter,
}: {
  header: Header<any, any>;
  isInCoverageTable?: boolean;
  resetFilter?: boolean
}) => {
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    if (
      header.column.getFilterValue() != '' &&
      header.column.getFilterValue() != undefined
    )
      setFilterOpen(true);
  }, []);

  const handleReset = () => {
    header.column.setFilterValue('')
    header.column.clearSorting()
    setFilterOpen(false);
  }

  const handleFilterKeyDown = (e:any) => {
    if(e.key === "Enter") setFilterOpen(true)
  }

  useEffect(() => {
    handleReset()
  }, [resetFilter]);

  return (
    <th>
      {filterOpen ? (
        <span
          className={`${style.tableHeader} ${style.withFilterOpen} ${
            isInCoverageTable && style.isInCoverageTable
          }`}
        >
          <InputGroup>
            <Form.Control
              placeholder='Search'
              value={header.column.getFilterValue() as string}
              onChange={(e) => header.column.setFilterValue(e.target.value)}
              className={style.columnFilter}
              autoFocus={true}
            />
            <X
              onClick={() => {
                handleReset()
              }}
              className={style.closeColumnFilter}
            />
          </InputGroup>
        </span>
      ) : (
        <span
          className={`${style.tableHeader} ${
            isInCoverageTable && style.isInCoverageTable
          }`}
        >
          <span
            className={`${style.tableHeader} ${
              isInCoverageTable && style.isInCoverageTable
            }`}
            {...{
              onClick: header.column.getToggleSortingHandler(),
            }}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            <span>
              {{
                false: <ChevronExpand className={style.tableIcon} tabIndex={0} onKeyDown={(event) => {
                  if (event.key === 'Enter') header.column.toggleSorting()
                }}/>,
                asc: <ChevronUp className={style.tableIcon} tabIndex={0} onKeyDown={(event) => {
                  if (event.key === 'Enter') header.column.toggleSorting()
                }}/>,
                desc: <ChevronDown className={style.tableIcon} tabIndex={0} onKeyDown={(event) => {
                  if (event.key === 'Enter') header.column.toggleSorting()
                }}/>,
              }[header.column.getIsSorted() as string] ?? null}
            </span>
          </span>
          <span>
            {header.column.getCanFilter() && (
              <Search
                onClick={() => setFilterOpen(true)}
                onKeyDown={handleFilterKeyDown}
                tabIndex={0}
                className={style.tableIcon}
              />
            )}
          </span>
        </span>
      )}
    </th>
  );
};
