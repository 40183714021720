import { useAuthState } from "app/okta/useAuthState";
import { useOktaAuth } from "@okta/okta-react";
import logo from "./assets/ProAgLogo.png";
import { useTheme, useMediaQuery } from "@mui/material";
import { List } from "react-bootstrap-icons";

import style from "./Layout.module.scss";
import {
  NavDropdown,
  Nav,
  Row,
  Col,
  Offcanvas,
  Accordion,
} from "react-bootstrap";
import { NavigationBar } from "./NavigationBar";
import { useState, useContext } from "react";
import { UseRolesContext } from "app/context";

export const Header = () => {
  const userInfo = useAuthState();
  const { oktaAuth } = useOktaAuth();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { role, isRoleLoading } = useContext(UseRolesContext);

  const path = window.location.pathname;

  const handleActiveClass = (href: any) => {
    return href == path ? style.mobileNavLinkActive : style.mobileNavLink;
  };

  return (
    <div>
      {isMatch ? (
        <>
          <div data-testid="header" className={style.header}>
            <div className={style.headerTop}>
              <a href="/home">
                <img src={logo} alt="logo" />
              </a>
              <span className={style.listIcon} onClick={handleShow}>
                <List size={"30px"} />
              </span>
            </div>
          </div>

          <Offcanvas
            show={show}
            onHide={handleClose}
            style={{ background: "#e3e8ed" }}
          >
            <Offcanvas.Header
              className="justify-content-end"
              closeButton
            ></Offcanvas.Header>
            <Offcanvas.Body>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/home" className={handleActiveClass("/home")}>
                  Home
                </Nav.Link>
                <Nav.Link href="/links" className={handleActiveClass("/links")}>
                  Links
                </Nav.Link>
                <Nav.Link
                  href="/calendar"
                  className={handleActiveClass("/calendar")}
                >
                  ProAg Calendar
                </Nav.Link>
                <Nav.Link
                  href="/employeeSearch"
                  className={handleActiveClass("/employeeSearch")}
                >
                  Employee Search
                </Nav.Link>
                <Nav.Link
                  href="https://www.proag.com/contact/"
                  target="_blank"
                  className={style.mobileNavLink}
                >
                  Contact Us
                </Nav.Link>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className={style.mobileNavAccordion}
                  >
                    <Accordion.Header>System Launch</Accordion.Header>
                    <Accordion.Body>
                      <Nav.Link href="https://my.proag.com/" target="_blank">
                        myProAg
                      </Nav.Link>
                      <Nav.Link
                        href="https://www.proag.com/subscriptions/"
                        target="_blank"
                      >
                        ProAg Messaging
                      </Nav.Link>
                      <Nav.Link
                        href="http://outlook.office365.com/"
                        target="_blank"
                      >
                        ProAg Webmail
                      </Nav.Link>
                      <Nav.Link
                        href="https://portal.proag.com/Systems/Pages/ProAgConnect.aspx"
                        target="_blank"
                      >
                        ProAgConnect
                      </Nav.Link>
                      <Nav.Link
                        href="https://mapping.proag.com/rmtwebcropmap/"
                        target="_blank"
                      >
                        ProAgMapping
                      </Nav.Link>
                      <Nav.Link href="https://quote.proag.com/" target="_blank">
                        ProAgQuote
                      </Nav.Link>
                      <Nav.Link
                        href="https://reports.proag.com/"
                        target="_blank"
                      >
                        ProAgReports
                      </Nav.Link>
                      <Nav.Link
                        href="https://proagu.proag.com/"
                        target="_blank"
                      >
                        ProAgU
                      </Nav.Link>
                      <Nav.Link
                        href="https://proagworks.proag.com/"
                        target="_blank"
                      >
                        ProAgWorks
                      </Nav.Link>
                    </Accordion.Body>
                  </Accordion.Item>
                  {userInfo?.given_name && userInfo?.family_name && (
                    <Accordion.Item
                      eventKey="1"
                      className={style.mobileNavAccordion}
                    >
                      <Accordion.Header className={style.user}>
                        <span className={style.name}>{userInfo.name}</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <NavDropdown.Item
                          style={{ color: "#000" }}
                          onClick={() => {
                            sessionStorage.clear();
                            localStorage.clear();
                            oktaAuth.signOut({
                              postLogoutRedirectUri: "https://proag.com",
                            });
                          }}
                        >
                          Logout
                        </NavDropdown.Item>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      ) : (
        <div data-testid="header" className={style.header}>
          <div className={style.headerTop}>
            <a href="/home">
              <img src={logo} alt="logo" />
            </a>
            <div className={style.dropdown}>
              {userInfo?.given_name && userInfo?.family_name && (
                <Nav className={style.user}>
                  <NavDropdown
                    title={<span className={style.name}>{userInfo.name}</span>}
                    className={style.user}
                  >
                    {role?.toLowerCase() === "administrator" && (
                      <>
                        <NavDropdown.Item
                          href="/admin"
                          style={{ color: "#000" }}
                        >
                          Admin
                        </NavDropdown.Item>
                        <NavDropdown.Divider />{" "}
                      </>
                    )}
                    <NavDropdown.Item
                      style={{ color: "#000" }}
                      onClick={() => {
                        sessionStorage.clear();
                        localStorage.clear();
                        oktaAuth.signOut({
                          postLogoutRedirectUri: "https://proag.com",
                        });
                      }}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              )}
            </div>
          </div>
          <NavigationBar></NavigationBar>
          <Row
            style={{
              background: "#A22A2A",
              color: "#ffffff",
              paddingLeft: "10px",
            }}
          ></Row>
        </div>
      )}
    </div>
  );
};
