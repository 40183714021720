import { Button, Modal } from "react-bootstrap";

export const DeleteConfirmation = (props: any) => {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button className="mx-2" variant="secondary" onClick={props.onHide}>
              Cancel
            </Button>
            <Button className="mx-2" variant="primary" onClick={props.onConfirm}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
