import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface NavigateCalendarProps {
  selectedDate: Date;
  handleDateChange?: (date: Date) => void;
}

export const NavigateCalendarComponent: React.FC<NavigateCalendarProps> = ({
  selectedDate,
  handleDateChange,
}) => {
  const onChange = (e: any) => {
    if (handleDateChange) handleDateChange(e);
  };
  return (
    <>
      <ReactDatePicker
        locale="en-US"
        placeholderText="Select date"
        onChange={onChange}
        onChangeRaw={(e) => e.preventDefault()}
        selected={selectedDate}
        isClearable
        autoComplete="off"
        showPopperArrow={false}
        showMonthYearPicker
        inline={true}
      />
    </>
  );
};
