import React, { useEffect } from 'react';
import { Outlet } from 'router6';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { LoadingSpinner } from 'components';
import { RequireRole } from 'app/context/RequireRole';
import ComposeContext from 'app/context/compose.context';
import { rootContext } from 'app/context/root.context';
import { RoleError } from 'components/RoleError';
import { Header } from 'components/navigation/Header';
import { Footer } from 'components/navigation/Footer';
import { RoleName } from 'app/apiGateway/useRole';

/**
 * This component ensures a logged in user
 * It creates the role context, includes a check for any assigned role, the page layout, and an Outlet
 * This is meant to be used in a <Route> as the element
 * @returns JSX.Element
 */

type Required={
    rolesPermission?:RoleName | RoleName[];
}

export const SecureRoutesLayoutRoles = (props:Required): JSX.Element => {
    const {rolesPermission}=props
    const { oktaAuth, authState } = useOktaAuth();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
    }, [oktaAuth, !!authState, authState?.isAuthenticated]);

    if (!authState || !authState?.isAuthenticated) {
        return <LoadingSpinner />;
    }

    return (
        <ComposeContext components={rootContext}>
        <RequireRole fallback={<RoleError />} requiredRole={rolesPermission}>
          <div>
            <Header/>
            <Outlet />
            <Footer/>
          </div>
        </RequireRole>
    </ComposeContext>
    )
}