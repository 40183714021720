import { ApiGateway } from "app/apiGateway/useApiGateway";
import { Graph } from "app/apiGateway/constants/ApplicationIdValues";

let apiGatewayGraphURL = (window as any).globalConfig.ApiGatewayGraph_URL;

export type CalendarEventEntityResponse = {
  id: number;
  categories: [];
  subject: string;
  bodyPreview: string;
  isAllDay: boolean;
  recurrence: {
    pattern: { dayofMonth: number; daysOfWeek: []; type: string };
    range: { endDate: string; startDate: string; type: string };
  };
  body: { content: string; contentType: string };
  start: { dateTime: string; timeZone: string };
  end: { dateTime: string; timeZone: string };
  location: {};
  hasAttachments: boolean;
  attachments: [];
};

export type CalendarEventResponse = {
  value: CalendarEventEntityResponse[];
};

export type CalendarViewEvent = {
  id: number;
  title: string;
  categories: [];
  isAllDay: boolean;
  start: Date;
  end: Date;
  location: {};
  hasAttachments: boolean;
  attachments: [];
};

export type CalendarEventsRequest = {
  selectedDate: Date;
};

const getCalendarEvents = async (apiGateway: ApiGateway, calendarEventsRequest: CalendarEventsRequest) =>  {
  let requestDate = calendarEventsRequest.selectedDate

  var firstDayCurrentMonth = new Date(requestDate.getFullYear(), requestDate.getMonth(), 1).toISOString();
  var firstDayNextMonth = new Date(requestDate.getFullYear(), requestDate.getMonth() + 1, 1).toISOString();

  const eventsApiUrl = apiGatewayGraphURL + `calendar/calendarView?startDateTime=`+firstDayCurrentMonth+`&endDateTime=`+firstDayNextMonth+`&$select= categories,recurrence,subject,body,start,end,location,hasAttachments &$top=200`
  const response = await apiGateway.get(eventsApiUrl, Graph) as CalendarEventResponse;

  if(response && response.value){
    const filterResponse = response?.value?.filter((x:any) => x.categories.some((y:any) => !y.includes('Notifications')) && x.categories?.length)
    response.value = filterResponse
  }
  return response;
}

const getSingleCalendarEvent = async (
  apiGateway: ApiGateway,
  eventId: string
) => {
  const getSingleEventUrl = apiGatewayGraphURL + `calendar/events/${eventId}`;
  return await apiGateway.get(getSingleEventUrl, Graph);
};

const getEventAttachments = async (apiGateway: ApiGateway, eventId: string) => {
  const singleEventApiUrl =
    apiGatewayGraphURL + `calendar/events/${eventId}/attachments`;

  return await apiGateway.get(singleEventApiUrl, Graph);
};

export const api = {
  getCalendarEvents,
  getSingleCalendarEvent,
  getEventAttachments,
};
