import style from "views/home/HomePage.module.scss";
import { useHomeAnnouncementQuery } from "views/home/api";
import { FileopenUrl } from "app/apiGateway/constants/ApplicationIdValues";
import { Link } from "router6";
import { NoDataComponent } from "components/exceptions/NoDataComponent";
import { LoadingSpinner } from "components/LoadingSpinner";

export const AnnouncementsComponent = () => {
  const { data, isLoading } =
    useHomeAnnouncementQuery();

  const announcements = data?.files;
  
  return (
    <div className={style.sectionTable}>
      <div className={style.sectionHeading}>Announcements</div>
      {!isLoading ? (
        <div className={style.sectionBody}>
          {announcements && announcements?.length ? (
            announcements?.map((announcement: any) => (
              <div key={announcement["id"]} className={style.sectionRow}>
                <a
                  href={`${FileopenUrl}${announcement["id"]}`}
                  target="_blank"
                  title={
                    announcement["metadata"] ?
                    announcement["metadata"]["metadataFields"]["title"] :
                    announcement["name"]
                  }
                  className={style.documentTitle}
                >
                  {" "}
                  {announcement["metadata"] ?
                  announcement["metadata"]["metadataFields"]["title"] :
                    announcement["name"].slice(
                      0,
                      announcement["name"].lastIndexOf(".")
                    )}{" "}
                </a>
                <div className={style.documentDate}>
                  {" "}
                  {new Date(announcement["modifiedAt"]).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}{" "}
                </div>
              </div>
            ))
          ) : (
              <NoDataComponent noDataMessage={"ProAg does not have any announcements to display at this time"}></NoDataComponent>
          )}
        </div>
      ): (
        <div className={style.loadingContainer}>
            <LoadingSpinner message="Loading Results...Please Wait"/>
          </div>
      )}
      {announcements && !isLoading && (
        <div className={style.viewAll}>
          <Link to="/searchResults?default=Announcements">View All</Link>
        </div>
      )}
    </div>
  );
};
