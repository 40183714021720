import { Nav } from "react-bootstrap";
import style from "./AdminView.module.scss";
import { useContext, useState } from "react";
import { UseRolesContext } from "app/context";
import AdminPanelSelection from "./components/AdminPanelSelection";
import { ErrorBoundary } from "react-error-boundary";
import { AdminErrorFallback } from "./components/AdminErrorFallback";
import { awsRumErrorHandler } from "app/errorBoundary";

const links = "content/urn:proag:portal:links";
const systemLaunch="content/urn:proag:portal:systemlaunch";

export const AdminView = () => {
  const [adminPanelSelection, setAdminPanelSelection] = useState(links);
  const { role, isRoleLoading } = useContext(UseRolesContext);

  if(isRoleLoading){
    return <div>Getting Role Loading....</div>
  }

  const handleActiveClass = (href:any) => {
    return href == adminPanelSelection ? style.activePanel : style.adminPanelTab
  }

  return (
    <>
      <div className={style.Container}>
        <Nav
          defaultActiveKey="links"
          className={`flex-column ${style.adminPanel}`}
        >
          <Nav.Item className={style.adminPanelHeader}>Admin Panel</Nav.Item>
          <Nav.Item onClick={() => setAdminPanelSelection(links)}>
            <Nav.Link className={handleActiveClass(links)}>
              Links
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setAdminPanelSelection(systemLaunch)}>
            <Nav.Link className={handleActiveClass(systemLaunch)} >
              System Launch
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {!isRoleLoading && <ErrorBoundary FallbackComponent={AdminErrorFallback} onError={(error, componentStack) => {awsRumErrorHandler(error, componentStack)}} >
        <AdminPanelSelection roles={role} panelId={adminPanelSelection}/>
      </ErrorBoundary>}
      </div>
    </>
  );
};
