import { FallbackProps } from "react-error-boundary";
import style from "./adminlinks.module.scss";
import { ExclamationCircle } from "react-bootstrap-icons";

export const AdminErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  return (
    <>
      <div className={style.links}>
        <div className={style.container}>
          <div className={style.message}>
            <ExclamationCircle className={style.icon} />
            <div>
              We apologize. Something went wrong with your request. Please
              contact the ProAg <a href="mailto: helpdesk@proag.com">Help Desk</a>.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
