import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { api } from './api';
import { useErrorBoundary } from 'react-error-boundary';

export const useEmployeeSearchQuery = () => {
  const apiGateway = useApiGateway();
  const { showBoundary } = useErrorBoundary();

  const { data, isFetching } = useQuery(
    ['empData'],
    async () =>    
         await api.getEmployeeList(apiGateway),
    {
      onSuccess: (data) => {
        if (!data) toast.info('You do not have any employees.');
      },
      // source: https://www.npmjs.com/package/react-error-boundary
      // Errors thrown in event handlers, or after async code has run, will not be caught.
      // hook showBoundary to pass those errors to the nearest error boundary
      onError: (error: any) => {showBoundary(error)},
    }
  );
  let employees;
  if(data?.value?.length){
    employees = data.value.sort((a, b) => a.surname?.toLowerCase().localeCompare(b.surname?.toLowerCase()))
  }
  return {
    employees,
    isEmployeeDataLoading: isFetching,  
  };
};
