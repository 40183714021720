import { RoleName } from "app/apiGateway/useRole";
import {
  ContentDataObject,
  HeaderObject,
  LinkObject,
} from "views/admin/api/api";

export const getSystemLaunchLinksService = (
  contentDataObject: ContentDataObject | undefined,
  role: RoleName | undefined
) => {
    
  if (contentDataObject) {
    const contentData = contentDataObject.data;

    const headerList: HeaderObject[] = JSON.parse(contentData);

    const sortedHeaders = headerList?.map((x: any) => {
      return {
        headerTitle: x.headerTitle,
        metadata: x.metadata,
        links: x.links?.sort((a: any, b: any) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        ),
      };
    });

    // find system launch header
    const systemLaunchHeader: HeaderObject[] = sortedHeaders?.filter(
      (header: any) => header.headerTitle === "System Launch"
    );

    const systemLaunch: LinkObject[] | undefined = systemLaunchHeader?.length
      ? systemLaunchHeader[0]?.links?.filter((link: any) =>
          link?.metadata?.authorization_role.includes(role)
        )
      : undefined;

    return systemLaunch != undefined ? systemLaunch : [];
  }
};
