import style from "../home/HomePage.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { UpcomingEventsComponent } from "./components/upcomingEvents/upcomingEvents";
import { AnnouncementsComponent } from "./components/announcements/announcements";
import { SearchBox } from "./components/search/search";
import { LatestDocumentsComponent } from "./components/latestDocments/latestDocuments";
import { SystemAlertsComponent } from "./components/systemAlerts/systemAlerts";
import { ErrorFallback, awsRumErrorHandler } from "app/errorBoundary";
import { ErrorBoundary } from "react-error-boundary";
import { HomePageError } from "./components/errors/HomePageError";

export const HomePage = () => {

  return (
    <div className={style.HomePage}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error, componentStack) => {awsRumErrorHandler(error, componentStack)}} >
        <SystemAlertsComponent />
      </ErrorBoundary>
      <Row>
        <Col>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={(error, componentStack) => {awsRumErrorHandler(error, componentStack)}} >
            <SearchBox />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <ErrorBoundary
            FallbackComponent={(props) => (
              <HomePageError
                a={{
                  error: props.error,
                  resetErrorBoundary: function (...args: any[]): void {
                    throw new Error("Function not implemented.");
                  },
                }}
                b={{ category: "Announcements" }}
                {...props}
              />
            )}
            onError={(error, componentStack) => {
              awsRumErrorHandler(error, componentStack);
            }}
          >
            {" "}
            <AnnouncementsComponent />
          </ErrorBoundary>
        </Col>
        <Col lg={6}>
          <ErrorBoundary
            FallbackComponent={(props) => (
              <HomePageError
                a={{
                  error: props.error,
                  resetErrorBoundary: function (...args: any[]): void {
                    throw new Error("Function not implemented.");
                  },
                }}
                b={{ category: "Upcoming Events" }}
                {...props}
              />
            )}
            onError={(error, componentStack) => {
              awsRumErrorHandler(error, componentStack);
            }}
          >
            {" "}
            <UpcomingEventsComponent />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col>
          <ErrorBoundary
            FallbackComponent={(props) => (
              <HomePageError
                a={{
                  error: props.error,
                  resetErrorBoundary: function (...args: any[]): void {
                    throw new Error("Function not implemented.");
                  },
                }}
                b={{ category: "Latest Documents" }}
                {...props}
              />
            )}
            onError={(error, componentStack) => {
              awsRumErrorHandler(error, componentStack);
            }}
          >
            {" "}
            <LatestDocumentsComponent />
          </ErrorBoundary>
        </Col>
      </Row>
    </div>
  );
};
