import { ContentDataObject, ContentObject, HeaderObject } from "../api/api";

export const getAdminContentService = (
  contentDataObject: ContentDataObject | undefined
) => {
    
  if (contentDataObject) {
    const contentData = contentDataObject.data;

    // array of HeaderObject
    const headerList: HeaderObject[] = JSON.parse(contentData);

    const sortedHeaders = headerList?.map((x:any) => {
      return {
        headerTitle: x.headerTitle,
        metadata: x.metadata,
        links: x.links?.sort((a:any, b:any) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
      }
    }).sort((a, b) => (a.headerTitle.toLowerCase() > b.headerTitle.toLowerCase() ? 1 : -1))


    const contentObject: ContentObject = {
      contentType: contentDataObject.contentType,
      contentId: contentDataObject.contentId,
      createdBy: contentDataObject.createdBy,
      createdDate: contentDataObject.createdDate,
      headers: sortedHeaders,
      modifiedBy: contentDataObject.modifiedBy,
      modifiedDate: contentDataObject.modifiedDate,
    };

    return contentObject;
  }

};
