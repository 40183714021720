import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { api } from 'views/home/api/api';
import { useErrorBoundary } from 'react-error-boundary';

export const useHomeDocumentsQuery = () => {
  const apiGateway = useApiGateway();
  const { showBoundary } = useErrorBoundary();

  const { data, isFetching } = useQuery(
    ['activeHomeDocuments'],
    async () =>    
         await api.getHomeDocuments(apiGateway),
    {      
      onSuccess: (data) => {
        if (!data.files) toast.info('You do not have any Latest Documents.');
      },
      // source: https://www.npmjs.com/package/react-error-boundary
      // Errors thrown in event handlers, or after async code has run, will not be caught.
      // hook showBoundary to pass those errors to the nearest error boundary
      onError: (error: any) => {showBoundary(error)},
    }
  );

  return {
    data: data,
    isLoading: isFetching,  
  };
};
