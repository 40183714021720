import style from "views/home/HomePage.module.scss";
import { useHomeDocumentsQuery } from "views/home/api";
import { FileopenUrl } from "app/apiGateway/constants/ApplicationIdValues";
import { Link } from "router6";
import { NoDataComponent } from "components/exceptions/NoDataComponent";
import { LoadingSpinner } from "components/LoadingSpinner";

const _ = require("lodash");
export const LatestDocumentsComponent = () => {
  const { data, isLoading } = useHomeDocumentsQuery();

  const activeDocuments = data?.files;

  return (
    <div className={style.documentsTable}>
      <div className={style.sectionHeading}>Latest Documents (all types)</div>
      {!isLoading ? (
        <div className={style.documentSectionBody}>
          {activeDocuments && activeDocuments?.length ? (
            _.orderBy(activeDocuments, ["modified_at"], ["desc"]).map(
              (latestdocument: any, index: any) => (
                <div key={latestdocument["id"]} className={style.documentsRow}>
                  <a
                    href={`${FileopenUrl}${latestdocument["id"]}`}
                    target="_blank"
                    title={
                      latestdocument["metadata"]
                        ? latestdocument["metadata"]["metadataFields"]["title"]
                        : latestdocument["name"]
                    }
                    className={style.documentName}
                  >
                    {" "}
                    {latestdocument["metadata"]
                      ? latestdocument["metadata"]["metadataFields"]["title"]
                      : latestdocument["name"].slice(
                          0,
                          latestdocument["name"].lastIndexOf(".")
                        )}{" "}
                  </a>
                  <div className={style.documentDate}>
                    {" "}
                    {new Date(latestdocument["modifiedAt"]).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}{" "}
                  </div>
                </div>
              )
            )
          ) : (
            <NoDataComponent
              noDataMessage={"There are no documents available at this time."}
            ></NoDataComponent>
          )}
        </div>
      ) : (
        <div className={style.loadingContainer}>
          <LoadingSpinner message="Loading Results...Please Wait" />
        </div>
      )}
      {activeDocuments && !isLoading && (
        <div className={style.viewAll}>
          <Link to="/searchResults?default=Documents">View All</Link>
        </div>
      )}
    </div>
  );
};
