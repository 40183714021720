import { useRole, roles } from 'app/apiGateway';
import { RoleName } from 'app/apiGateway/useRole';
import React, {
    createContext,
    ReactElement,
    FC,
    PropsWithChildren,
    useState,
    useEffect
  } from 'react';

  interface IRolesContext {
    role?: RoleName;
    isRoleLoading: boolean;
  }
  
  
  
  export const UseRolesContext = createContext<IRolesContext>({
    role: '',
    isRoleLoading: false,
  });
  
  export const UseRolesContextProvider: FC<PropsWithChildren> = (
    props,
  ): ReactElement => {
    const { role, isRoleLoading } = useRole();
    const [userRole, setUserRole] = useState(role)
    useEffect(() => {
      setUserRole(role)
    }, [isRoleLoading])
    return (
      <UseRolesContext.Provider
        value={{
            role: userRole,
            isRoleLoading: isRoleLoading
        }}
      >
        {props.children}
      </UseRolesContext.Provider>
    );
  };
  