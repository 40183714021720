import { FileopenUrl } from 'app/apiGateway/constants/ApplicationIdValues';
import { ApiGateway } from 'app/apiGateway/useApiGateway';
import { ContentMgmt, LinksContentUrl } from 'app/apiGateway/constants/ApplicationIdValues';
import { ContentDataObject } from 'views/admin/api/api';

const getLinks = async (apiGateway: ApiGateway) => 
  (await apiGateway.get(LinksContentUrl, ContentMgmt)) as ContentDataObject;

export const api = {
  getLinks,
};