import { useNavigate } from 'react-router';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { config } from './config';

export const OktaAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const oktaAuth = new OktaAuth(config.oidc);
  const navigate = useNavigate();

  oktaAuth.start();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};