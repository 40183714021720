import { useAdminLinksQuery, useSaveContentMutation } from "../api";
import { Accordion, Button} from "react-bootstrap";
import { PencilFill, Trash3Fill } from "react-bootstrap-icons";
import style from "./adminlinks.module.scss";
import AdminModalPopUpForm from "./AdminModalPopUpForm";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useApiGateway } from "app/apiGateway";
import { putAdminContentService } from "../service/putAdminContentService";
import { UseRolesContext } from "app/context";
import { useAuthState } from "app/okta/useAuthState";
import { getAdminContentService } from "../service/getAdminContentService";

type AdminPanelProps = {
  roles?: string | string[];
  panelId: string;
};

type modalForm = {
    parentName?: string;
    childName?: string;
    childLink?: string;
    roles: string[];
    childShowHide?: boolean;
    parentIds: string;
    childIds: string;
    HeaderShowHide: boolean;
    createdBy:string;
    createdDate:string;
    ModifiedBy:string;
    ModifiedDate:string;
  };

const AdminPanelSelection = (props: AdminPanelProps) => {
  const { roles, panelId } = props;
  const arrayRoles = new Array(roles ?? "").flat();
  const [fetchKey, setFetchKey] = useState(0);
  const userInfo = useAuthState();
  const username=userInfo?.name ?? "";
  const { saveContentQuery } = useSaveContentMutation();
  const { data, isLoading } = useAdminLinksQuery(fetchKey, panelId);
  const apiGateway = useApiGateway();
  const adminLinks = getAdminContentService(data);

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [parentId, setParentId] = useState("");
  const [childId, setChildId] = useState("");
  const [titleHead, setTitleHead] = useState("");
  const [formModalShow, setFormModalShow] = useState(false);
  const [headerRole, setHeaderRole] = useState([]);
  const [modalFormData, setModalFormData] = useState<modalForm>({
    parentName: "",
    childName: "",
    childLink: "",
    roles: [],
    parentIds: "",
    childIds: "0",
    childShowHide: false,
    HeaderShowHide: true,
    createdBy:"",
    createdDate:"",
    ModifiedBy:"",
    ModifiedDate:"",
  });

  const headerData = (
    e: any,
    parentHeading: string,
    parentRole: any,
    parentIndex: string,
    childIndex: string,
    createBy:string,
    createDate:string,
    ModifyBy:string,
    ModifyDate:string,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setTitleHead(parentHeading);
    setModalFormData({
        parentName: parentHeading,
        childName: "",
        childLink: "",
        roles: parentRole,
        parentIds: parentIndex,
        childIds: childIndex,
        childShowHide: false,
        HeaderShowHide: true,
        createdBy:createBy,
    createdDate:createDate,
    ModifiedBy:ModifyBy,
    ModifiedDate:ModifyDate,
    });
    setHeaderRole([]);
    setFormModalShow(true);
  };

  const linkData = (
    e: any,
    parentHeading: string,
    childHeading: string,
    childLink: string,
    childRole: any,
    parentIndex: string,
    childIndex: string,
    createBy:string,
    createDate:string,
    ModifyBy:string,
    ModifyDate:string,
    parentRole:any
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setTitleHead(parentHeading);
    setModalFormData({
        parentName: parentHeading,
        childName: childHeading,
        childLink: childLink,
        roles: childRole,
        parentIds: parentIndex,
        childIds: childIndex,
        childShowHide: true,
        HeaderShowHide: false,
        createdBy:createBy,
    createdDate:createDate,
    ModifiedBy:ModifyBy,
    ModifiedDate:ModifyDate,
    });
    setHeaderRole(parentRole);
    setFormModalShow(true);
  };

  const deletePortalLinks = (
    e: any,
    parentIndex: string,
    childIndex: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setParentId(parentIndex);
    setChildId(childIndex);
    setDeleteModalShow(true);
  };

  const DeleteConfirm = () => {
    const data = {
      parentId: parentId,
      childId: childId,
    };

    const service = putAdminContentService(adminLinks, data, true, username);

    saveContentQuery(
      { apiGateway, request: service.saveObject, adminLinksUrl: panelId },
      {
        onSuccess: (data: string) => {
          setFormModalShow(false);
          setFetchKey(fetchKey + 1);

          toast.success(
            `Link Deleted Successfully`
          );
        },
      }
    );

    setParentId("");
    setChildId("");
    setDeleteModalShow(false);
  };

  const isValidHttpUrl = (link : any) => {
    if (!link) {
      return false;
    }

    // for valid urls:
    // enforce valid protocol prefix (e.g., http, https, ftp, ftps)
    // a mininum 3 char domain name or 2 char suffix
    // allow for port numbers (e.g., localhost:3000)
    // allow any kind of valid url parameters
    const regex = new RegExp('^((http|https|ftp|ftps):\/\/)+[a-z]{1}[a-zA-Z0-9@:=_~#\%\+\-\.\/\?\&]{0,256}[a-z]{2,6}[a-zA-Z0-9@:=_~#\%\+\-\.\/\?\&\;]*$');
    return regex.test(link);
  }

  const handleSubmitData = (data: any) => {
    const title = data.childName || data.parentName;
    const link = data.childLink || "";
    const service = putAdminContentService(adminLinks, data, false, username);
    const isSublink = data.childIds !== "0";

    if (service.duplicates) {
      const displayName = link ? `\"${link}\" or \"${title}\"` : `\"${title}\"`;
      toast.error(`${displayName} already exists.`);
      return false;

    } else if (isSublink && !isValidHttpUrl(link)) {
      toast.error(`Invalid URL. Please enter valid url format (e.g., http://example.com or https://example.com) in the link.`);
      return false;

    } else {
      const hasParentId = Boolean(data.parentIds);
      const hasChildId = Boolean(data.childIds);
      const isAdded = Boolean((!hasParentId && data.childIds === "0") || (hasParentId && !hasChildId));

      saveContentQuery(
        { apiGateway, request: service.saveObject, adminLinksUrl: panelId },
        {
          onSuccess: (data: string) => {
            let successVerbiage = isAdded ? "added" : "updated";

            setFormModalShow(false);
            setFetchKey(fetchKey + 1);

            toast.success(`Link \"${title}\" was ${successVerbiage} successfully.`);
          },
        }
      );
    }
  };

  return (
    <>
      <div className={style.links}>
        <Accordion>
            {adminLinks?.headers.map((header: any, parentindex: any) => {
              return (
                  <Accordion.Item eventKey={parentindex} key={parentindex}>
                    <Accordion.Header className={style.accordionHeader}>
                      {header.headerTitle}
                      <span>
                          <PencilFill
                            className={`mx-2 ${style.iconBtn}`}
                            onClick={(e) =>
                              headerData(
                                e,
                                header.headerTitle,
                                header.metadata.authorization_role,
                                `parentIndex-${parentindex}`,
                                "0",header.metadata.createdBy,header.metadata.createdDate,header.metadata.modifiedBy,
                                header.metadata.modifiedDate
                              )
                            }
                          />
                          <Trash3Fill
                            className={`mx-2 ${style.iconBtn}`}
                            onClick={(e) =>
                              deletePortalLinks(
                                e,
                                `parentIndex-${parentindex}`,
                                ""
                              )
                            }
                          />
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={style.linksWrapper}>
                      <div className={style.btnDiv}>
                            <Button
                              className={style.addLinkBtn}
                              onClick={(e) => {
                                linkData(
                                  e,
                                  header.headerTitle,
                                  "",
                                  "",
                                  arrayRoles,
                                  `parentIndex-${parentindex}`,
                                  "","","","","",
                                  header.metadata.authorization_role
                                );
                              }}
                            >
                              Add Link
                            </Button>
                          </div>
                        {header.links?.map((link: any, childindex: string) => {
                          
                            return (
                              <div className={style.childLink} key={childindex}>
                                <a
                                  key={`${link.title}-${childindex}`}
                                  href={link.link}
                                  target="_blank"
                                  rel="noopener"
                                >
                                  {link.title}
                                </a><span>
                                    <PencilFill
                                      className={`mx-2 ${style.iconBtn}`}
                                      onClick={(e) =>
                                        linkData(
                                          e,
                                          header.headerTitle,
                                          link.title,
                                          link.link,
                                          link.metadata.authorization_role,
                                          `parentIndex-${parentindex}`,
                                          `childIndex-${childindex}`,
                                          link.metadata.createdBy,link.metadata.createdDate,link.metadata.modifiedBy,
                                          link.metadata.modifiedDate,
                                          header.metadata.authorization_role
                                        )
                                      }                                    
                                    />
                                    <Trash3Fill
                                      className={`mx-2 ${style.iconBtn}`}
                                      onClick={(e) =>
                                        deletePortalLinks(
                                          e,
                                          `parentIndex-${parentindex}`,
                                          `childIndex-${childindex}`
                                        )
                                      }
                                    />
                                  </span>
                              </div>
                            );
                        }
                        )}            
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
            })} 
        </Accordion>
        <div className={style.btnDiv}>
          <Button
	        className={style.btn}
          onClick={() => {
            setTitleHead("Add a Group Heading");
            setModalFormData({
                parentName: "",
                childName: "",
                childLink: "",
                roles: arrayRoles,
                parentIds: "",
                childIds: "0",
                childShowHide: false,
                HeaderShowHide: true,
                createdBy:"",
                createdDate:"",
                ModifiedBy:"",
                ModifiedDate:"",             
            });
            setHeaderRole([]);
            setFormModalShow(true);
          }}
        >
          Add a Group Heading
          </Button>
        </div>
      </div>
      {adminLinks && (
        <>
          <AdminModalPopUpForm
            show={formModalShow}
            hide={() => setFormModalShow(false)}
            modalData={modalFormData}
            submitData={(data: any) => handleSubmitData(data)}
            titleName={titleHead}
            roleList={headerRole}
          />
          <DeleteConfirmation
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
            onConfirm={() => DeleteConfirm()}
          />
        </>
      )}
    </>
  );
};

export default AdminPanelSelection;
