import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Button, Form } from "react-bootstrap";
import {
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";

type defaultForm = {
  parentName?: string;
  childName?: string;
  childLink?: string;
  roles: string[];
  childShowHide?: boolean;
  parentIds: string;
  childIds: string;
  HeaderShowHide?: boolean;
  createdBy:string;
  createdDate:string;
  ModifiedBy:string;
  ModifiedDate:string; 
};

type Props = {
  show: boolean;
  hide: () => void;
  modalData: defaultForm;
  submitData:any;
  titleName?:string;
  roleList:any;
};

const UserRoles = ["Administrator", "Internal", "External"];

const AdminModalPopUpForm = (props: Props) => {
  const [updateModel, setUpdateModel] = useState<defaultForm>(props.modalData);
  const { handleSubmit, control, reset, setValue } = useForm<defaultForm>({
    defaultValues: updateModel,
  });
  const [RolesDropDown,setRolesDropDown]=useState(UserRoles);

  useEffect(() => {
    setUpdateModel({
      parentName: props.modalData.parentName,
      childName: props.modalData.childName,
      childLink: props.modalData.childLink,
      roles: props.modalData.roles,
      childShowHide: props.modalData.childShowHide,
      HeaderShowHide: props.modalData.HeaderShowHide,
      parentIds: props.modalData.parentIds,
      childIds: props.modalData.childIds,
      createdBy:props.modalData.createdBy,
      createdDate:props.modalData.createdDate,
      ModifiedBy:props.modalData.ModifiedBy,
      ModifiedDate:props.modalData.ModifiedDate,  
    });
    setRolesDropDown(props.roleList.length>0?props.roleList: UserRoles);
    //setValue(props.roles);
  }, [props]);

  useEffect(() => {
    reset(updateModel);
  }, [updateModel]);

  const handleReturnValue = (newValue: any) => {
    if (newValue.includes("External")) {
      return RolesDropDown;
    } else if (newValue.includes("Internal")) {
      return [RolesDropDown[0], RolesDropDown[1]];
    } else {
      return newValue;
    }
  };


  return (
    <Dialog
      open={props.show}
      fullWidth
      maxWidth={"md"}
      //onClose={handleClose}
    >
      <DialogTitle
        sx={{ color: "#28764c", borderBottom: "1px solid lightgray" }}
      >
        {props.titleName}
        <IconButton
          aria-label="close"
          onClick={props.hide}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "gray",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        component="form"
        sx={{ mt: 2 }}
        onSubmit={handleSubmit(props.submitData)}
      >
        <DialogContent>
          <Grid container spacing={3}>
            {props.modalData.HeaderShowHide && (
              <>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Typography
                    component="label"
                    sx={{ fontWeight: "800", marginLeft: "25%" }}
                  >
                    Group Name* :
                  </Typography>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                  <Controller
                    control={control}
                    name="parentName"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <Form.Control
                          {...field}
                          type="text"
                          name="parentName"
                          isInvalid={error !== undefined}
                        />
                        <Form.Control.Feedback type="invalid">
                          {error ? "Field is required" : ""}
                        </Form.Control.Feedback>
                      </>
                    )}
                  />
                </Grid>
              </>
            )}
            {props.modalData.childShowHide && (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginLeft: "1.5%", paddingTop: "2%" }}
                >
                  <Grid item xs={9} sm={3} md={1} lg={3} xl={3}>
                    <Typography
                      component="label"
                      sx={{ fontWeight: "800", marginLeft: "22%" }}
                    >
                      Title on Page*:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                    <Controller
                      control={control}
                      name="childName"
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: { ...field },
                        fieldState: { error },
                      }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            name="childName"
                            isInvalid={error !== undefined}
                          />
                          <Form.Control.Feedback type="invalid">
                            {error ? "Field is required" : ""}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginLeft: "1.5%", paddingTop: "2%" }}
                >
                  <Grid item xs={9} sm={3} md={1} lg={3} xl={3}>
                    <Typography
                      component="label"
                      sx={{ fontWeight: "800", marginLeft: "22%" }}
                    >
                      Link/Web Address*:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                    <Controller
                      control={control}
                      name="childLink"
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: { ...field },
                        fieldState: { error },
                      }) => (
                        <>
                          <Form.Control
                            {...field}
                            type="text"
                            name="childLink"
                            isInvalid={error !== undefined}
                          />
                          <Form.Control.Feedback type="invalid">
                            {error ? "Field is required" : ""}
                          </Form.Control.Feedback>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              container
              spacing={2}
              sx={{ marginLeft: "1.5%", paddingTop: "2%" }}
            >
              <Grid item xs={9} sm={3} md={1} lg={3} xl={3}>
                <Typography
                  component="label"
                  sx={{ fontWeight: "800", marginLeft: "22%" }}
                >
                  Role Permission* :
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  control={control}
                  name="roles"
                  render={({ field: { ...field }, fieldState: { error } }) => (
                    <Autocomplete
                      fullWidth
                      multiple
                      value={field.value || []}
                      onChange={(_, data) =>
                        field.onChange(data)
                      }
                      options={RolesDropDown}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          variant="outlined"
                          sx={{
                            "&:hover fieldset": {
                              borderColor: "rgba(0, 0, 0, 0.23) !important",
                            },
                            "&:focus-within fieldset, &:focus-visible fieldset":
                              {
                                border: "1px solid #28764c!important",
                                //boxShadow: 3,
                                outlineOffset: "0 !important",
                                boxShadow:
                                  "0 0 0 0.25rem rgba(0, 76, 35, 0.1) !important",
                                //borderColor: "rgba(0, 76, 35, 0.75) !important",
                              },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid lightgray", mt: 2,py:3,display:"flex",justifyContent:"space-between" }}>      
        <div>
            {props.modalData.createdBy && <p>Created On:{props.modalData.createdDate} by {props.modalData.createdBy}</p> }
            {props.modalData.ModifiedBy && <p>Modified On:{props.modalData.ModifiedDate} by {props.modalData.ModifiedBy}</p>}
            </div> 
          <div className="d-flex gap-2">
            <Button
              variant="primary"
              type="submit"
              style={{ width: "200px"}}
            >
              Save
            </Button>
            <Button
              variant="secondary"
              style={{ width: "200px" }}
              onClick={props.hide}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AdminModalPopUpForm;
