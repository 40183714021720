import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { api } from './api';

export const useSingleCalendarEventQuery = (eventId: any) => {
  const apiGateway = useApiGateway();
  const { data, isFetching } = useQuery(
    ['activeEvent'],
    async () =>    
         await api.getSingleCalendarEvent(apiGateway, eventId),
    {      
      onSuccess: (data) => {
        if (!data) toast.info('You do not have any Upcoming Events.');        
      },
        onError: (error) => {
          toast.error('An error has occurred with your request. Please try again or contact your sales representative. Error message:' + error)
        },
        }
  );

  return {
    event: data,
    isLoading: isFetching,  
  };
};
