import { useContext } from "react";
import { RoleName } from "app/apiGateway/useRole";
import { UseRolesContext } from "./useRolesContext/useRolesContext";

type RequiredRoleType = RoleName | RoleName[];

interface IRequireRoleProps {
    /**The children elements*/
    children: JSX.Element;
    /**If set, the user will be required to have this role. If not set, the user is required to have any role.*/
    requiredRole?: RequiredRoleType;
    /**The component to display if the user fails the role check*/
    fallback?: JSX.Element;
}

const defaultFallback = <>Required role not assigned</>;

/**
 * Ensures a user has a role. Displays children elements if role check passes, else displays the fallback component.
 * @param { children, requiredRole, fallback } - The props
 * @returns JSX.Element
 */
export const RequireRole = ({ children, requiredRole, fallback }: IRequireRoleProps): JSX.Element | null => {
    const { role, isRoleLoading } = useContext(UseRolesContext);

    const getContent = () => {
        if (requiredRole !== undefined) {
            if ((Array.isArray(requiredRole) && role !== undefined && requiredRole.includes(role)) ||
                (!Array.isArray(requiredRole) && role === requiredRole)) {
                return children;
            }
            else { return fallback ?? defaultFallback; }
        }
        else {
            if (role !== undefined && role !== '') {
                return children;
            }
            else {
                return fallback ?? defaultFallback;
            }
        }
    };

    return (
        isRoleLoading ? null: getContent()
    )
}