import moment from "moment";
import { RRule } from "rrule";
import { CalendarEventEntityResponse } from "../api/api";

export const eventsService = (
   events: CalendarEventEntityResponse[]
  ) => {
    const checkEvent = (event: any) => event.recurrence != null;
    const recurringEvents = events?.filter(checkEvent);
    const checkEventIsRecurreingEvent = (event: any) => recurringEvents.some((rEvent) => rEvent.id == event.id);
    const filteredEvents = recurringEvents ? events?.filter(function(x) { return !checkEventIsRecurreingEvent(x)}) : events;

    function convertDateToLocalDate(date: Date) {
      const showTime = date.getHours()
        + ':' + date.getMinutes()
        + ":" + date.getSeconds();
        if(showTime !== "0:0:0"){
          var newDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000
          );
          return newDate;
        }    
    return date;
    }

    const formatRecurringEvents = recurringEvents?.map(function (recEvent: any) {
        const start = new Date(recEvent.start.dateTime)
        const end = new Date(recEvent.end.dateTime);
        const startDate = new Date(Date.UTC(start.getFullYear(),start.getMonth(),start.getDate(),start.getHours(),start.getMinutes(),0))
        const endDate = new Date(Date.UTC(end.getFullYear(),end.getMonth(),end.getDate(),end.getHours(),end.getMinutes(),0))
    
        var m = moment.tz.guess();
        
        const frequency = () => {
          switch(recEvent.recurrence.pattern.type) {
            case 'daily': return RRule.DAILY;
            case 'weekly': return RRule.WEEKLY;
            case 'absoluteMonthly': return RRule.MONTHLY;
            case 'yearly': return RRule.YEARLY;
            default: return RRule.DAILY;
        } }
    
        const f = frequency()

        const rWeekday = (dayOfWeek: string) => {
          switch(dayOfWeek) {
            case 'monday': return 'MO';
            case 'tuesday': return 'TU';
            case 'wednesday': return 'WE';
            case 'thursday': return 'TH';
            case 'friday': return 'FR';
            case 'saturday': return 'SA';
            case 'sunday': return 'SU';
            default: return dayOfWeek;
          }}
    
        const startDates = new RRule({
          freq: f,
          interval: recEvent.recurrence.pattern.interval,
          tzid: m,
          dtstart: startDate,
          byweekday: recEvent.recurrence.pattern.daysOfWeek?.map( (day: string) => rWeekday(day)),
          until: new Date(recEvent.recurrence.range.endDate),
        })
    
        const endDates = new RRule({
          freq: f,
          interval: recEvent.recurrence.pattern.interval,
          tzid: m,
          dtstart: endDate,
          byweekday: recEvent.recurrence.pattern.daysOfWeek?.map( (day: string) => rWeekday(day)),
          until: new Date(recEvent.recurrence.range.endDate)
        })
    
        const startList = startDates.all();
        const endList = endDates.all();
    
        var mergeLists = startList.map(function (x, i) { 
          return [x, endList[i]] 
        });
    
        const newListEvents = mergeLists?.map(function (s) {
            return {
              id: recEvent.id,
              title: recEvent.subject,
              categories: recEvent.categories,
              start: new Date(s[0]),
              end: new Date(s[1]),
            }
        })
    
        return newListEvents
      })

        let convertEvents: any[] | undefined = filteredEvents?.map(function (event: any) {
            const start = new Date(event.start.dateTime);
            const end = new Date(event.end.dateTime);
            //considering its a fullday event If the difference time of start and end dates is 24 hrs
         
            return {
              id: event.id,
              title: event.subject,
              start: convertDateToLocalDate(start),
              end: convertDateToLocalDate(end),
              categories: event.categories,
            };
          });

      const listRecEvents = formatRecurringEvents?.flat()

      let combinedEvents = formatRecurringEvents ? convertEvents?.concat(listRecEvents) : convertEvents
  
    return combinedEvents
  };