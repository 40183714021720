import { ExclamationCircle } from "react-bootstrap-icons";
import { FallbackProps } from "react-error-boundary";
import style from "views/home/HomePage.module.scss";

interface ErrorFallbackProps {
  a: FallbackProps;
  b: CustomProps;
}
interface CustomProps {
  category: string;
}

export const HomePageError: React.FC<ErrorFallbackProps> = (props) => {
  let message = "";
  if (props.a.error) {
    message = "Error message: " + props.a.error.message;
  }

  return (
    <>
      <div className={style.sectionTable}>
        <div className={style.sectionHeading}>{props.b.category}</div>
        <div className={style.errorContainer}>
          <div className={style.message}>
            <ExclamationCircle className={style.icon} />
            <div>Something went wrong</div>
          </div>
          <a href="mailto:helpdesk@proag.com" className={style.contact}>
            Contact Support
          </a>
        </div>
      </div>
    </>
  );
};
