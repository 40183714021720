import style from "./Search.module.scss";
import { Table } from "react-bootstrap";
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import {
  ColumnTextFilter,
  Pagination,
} from "components/tables";

import { SearchResultTableRow } from "../type/SearchResultTableRow";
import React, { useMemo, useState, useEffect } from "react";
import {
  MetadataDocumentResponse,
  SearchResultRequest,
} from "../api/api";
import { FileopenUrl } from "app/apiGateway/constants/ApplicationIdValues";
import { mapSearchResultResponseForTable } from "../service/mapSearchResults";

interface SearchResultTableProps {
  searchResultRequest: SearchResultRequest;
  results: MetadataDocumentResponse | undefined;
  isSearchTypeAllText: boolean;
  resetTableFilters: boolean;
  onPaginationChange?: (newValue: any) => void;
  isNewSearch:boolean;
}

const paginationState = {
  pageIndex: 0,
  pageSize: 20,
};

export const SearchResultTable: React.FC<SearchResultTableProps> = ({
  searchResultRequest,
  results,
  isSearchTypeAllText,
  resetTableFilters,
  onPaginationChange,
  isNewSearch,
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [pagination, setPagination] =
    useState<PaginationState>(paginationState);

  const data = mapSearchResultResponseForTable(results?.files);

  const columns = useMemo(
    (): ColumnDef<SearchResultTableRow>[] => [
      {
        id: "result",
        accessorFn: (row) => row.title,
        cell: (info) => info.getValue(),
        header: () => <span>Search Result</span>,
        sortingFn: "alphanumeric",
        filterFn: (row, _columnId, value) => {
          if (
            !row.original.title
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase())
          )
            return false;
          return true;
        },
      },
      {
        id: "keywords",
        accessorFn: (row) => row.alternateSearchTerms,
        cell: (info) => info.getValue(),
        header: () => <span>Keywords</span>,
        sortingFn: "alphanumeric",
        filterFn: (row, _columnId, value) => {
          if (
            !row.original.alternateSearchTerms
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase())
          )
            return false;
          return true;
        },
      },
      {
        id: "type",
        accessorFn: (row) => row.type,
        cell: (info) => info.getValue(),
        header: () => <span>Type</span>,
        sortingFn: "alphanumeric",
        filterFn: (row, _columnId, value) => {
          if (
            !row.original.type
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase())
          )
            return false;
          return true;
        },
      },
      {
        id: "dateModified",
        accessorFn: (row) => row.dateModified,
        cell: (info) => {
          const date = info.getValue();
          if (typeof date == "string") {
            return new Date(date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
          }
          return date;
        },
        header: () => <span>Date Modified</span>,
        filterFn: (row, _columnId, value) => {
          if (
            !new Date(row.original.dateModified)
              .toLocaleString()
              .includes(value)
          )
            return false;
          return true;
        },
      },
    ],
    []
  );
  useEffect(() => {
    if (!searchResultRequest.isMetadataSearch) handlePagination();
  }, [pagination.pageIndex]);

  useEffect(() => {   
      setPagination({ pageIndex: 0,
        pageSize: 20,})
  }, [isNewSearch]);

  const handlePagination = () => {
    if (onPaginationChange) onPaginationChange(pagination.pageIndex);
  };

  const totalResults = searchResultRequest.isMetadataSearch
    ? results?.files?.length
    : results?.totalCount;

  const pages = totalResults
    ? Math.ceil(totalResults / pagination.pageSize)
    : 0;

  const table = useReactTable<SearchResultTableRow>({
    columns,
    data,
    state: { expanded, sorting, pagination, rowSelection },
    manualPagination: !searchResultRequest.isMetadataSearch,
    pageCount: pages,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: () => true,
    onExpandedChange: setExpanded,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    sortDescFirst: false,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    autoResetPageIndex: false,
  });
  
  return (
    <div style={{ minHeight: "500px" }}>
      <Pagination data={data} table={table} total={totalResults} />
      <Table striped hover responsive className={style.tableContainer}>
        <thead>
          {(() => {
            const { headers } = table.getHeaderGroups()[0];
            return (
              <tr>
                <th>
                  <div className={style.tableHeader}>
                  <ColumnTextFilter resetFilter={resetTableFilters} header={headers[0]} />
                  </div>
                </th>
                <th>
                  <div className={style.tableHeader}>
                    <ColumnTextFilter resetFilter={resetTableFilters} header={headers[1]} />
                  </div>
                </th>
                <th>
                  <div className={style.tableHeader}>
                    <ColumnTextFilter resetFilter={resetTableFilters} header={headers[2]} />
                  </div>
                </th>
                <th>
                  <div className={style.tableHeader}>
                    <ColumnTextFilter resetFilter={resetTableFilters} header={headers[3]} />
                  </div>
                </th>
              </tr>
            );
          })()}
        </thead>
        {results && (
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const cells = row.getVisibleCells();
              return (
                <tr key={row.id}>
                  <td>
                    <div className="d-flex flex-column">
                      <a
                        href={`${FileopenUrl}${row["original"]["id"]}`}
                        className={style.documentName}
                        target="_blank"
                      >
                        {flexRender(
                          cells[0].column.columnDef.cell,
                          cells[0].getContext()
                        )}
                      </a>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      <div
                        className={style.keywords}
                        title={row["original"]["alternateSearchTerms"]}
                      >
                        {flexRender(
                          cells[1].column.columnDef.cell,
                          cells[1].getContext()
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {flexRender(
                        cells[2].column.columnDef.cell,
                        cells[2].getContext()
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column">
                      {flexRender(
                        cells[3].column.columnDef.cell,
                        cells[3].getContext()
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {results && results?.files?.length < 1 && (
        <div className={style.noDataMessage}>
          No documents found using the search criteria entered. Please revise your search and try again.
        </div>
      )}
    </div>
  );
};
