import { ApiGateway } from "app/apiGateway/useApiGateway";
import { ContentMgmt } from "app/apiGateway/constants/ApplicationIdValues";

export type ContentDataObject = {
  contentType: string;
  contentId: string;
  createdBy: string;
  createdDate: string;
  data: string;
  modifiedBy: string;
  modifiedDate: string;
};

export type ContentObject = {
  contentType: string;
  contentId: string;
  createdBy: string;
  createdDate: string;
  headers: HeaderObject[];
  modifiedBy: string;
  modifiedDate: string;
};

export type HeaderObject = {
  headerTitle: string;
  metadata: HeaderGroupMetadata;
  links: LinkObject[];
};

export type HeaderGroupMetadata = {
  authorization_role: [];
  modifiedBy: string;
  modifiedDate: string;
  createdBy : string,
  createdDate : string
};

export type LinkObject = {
  title: string;
  metadata: SublinkMetadata;
  link: string;
};

export type SublinkMetadata = {
  authorization_role: string[];
  modifiedBy: string;
  modifiedDate: string;
  createdBy : string,
  createdDate : string
};

const getAdminLinks = async (apiGateway: ApiGateway, adminLinksUrl: string) => 
  (await apiGateway.get(adminLinksUrl, ContentMgmt)) as ContentDataObject;

const saveContent = async ({
  apiGateway,
  request,
  adminLinksUrl,
}: {
  apiGateway: ApiGateway;
  request: ContentDataObject;
  adminLinksUrl: string;
}) => await apiGateway.put(adminLinksUrl, ContentMgmt, request);

export const api = {
  getAdminLinks,
  saveContent,
};
