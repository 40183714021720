import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { api } from './api';
import { useErrorBoundary } from 'react-error-boundary';
import { getAdminContentService } from '../service/getAdminContentService';

export const useAdminLinksQuery = (fetchKey:any, panelId: any) => {
  const apiGateway = useApiGateway();
  const { showBoundary } = useErrorBoundary();

  const { data, isFetching, isSuccess } = useQuery(
    [fetchKey, panelId],
    async () =>    
         await api.getAdminLinks(apiGateway, panelId),
    {
      onSuccess: (data) => {
        if (!data) toast.info('You do not have any links.');
      },
      // source: https://www.npmjs.com/package/react-error-boundary
      // Errors thrown in event handlers, or after async code has run, will not be caught.
      // hook showBoundary to pass those errors to the nearest error boundary
      onError: (error: any) => {showBoundary(error)},
    }
  );

  return {
    data,
    isLoading: isFetching, 
    isSuccess 
  };
};