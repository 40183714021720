import style from "./FileUpload.module.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

export const FileUpload = () => {
  const navigate = useNavigate();

  let fileUploadUrl = (window as any).globalConfig?.FileUpload_Url;
  return (
    <>
      <div className={style.containerClass}>
      <div className={style.backBtnContainerTop}>
          <Button onClick={() => navigate(-1)} className={style.BackButton}>
            <span>Back</span>
          </Button>
        </div>
        <div className={style.iFrameContainer}>
          <iframe
            src={fileUploadUrl}
            className={style.fileUpload}
          ></iframe>
        </div>
        <div className={style.backBtnContainer}>
          <Button onClick={() => navigate(-1)} className={style.BackButton}>
            <span>Back</span>
          </Button>
        </div>
      </div>
    </>
  );
};
