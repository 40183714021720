import { ApiGateway } from 'app/apiGateway/useApiGateway';
import { Graph } from "app/apiGateway/constants/ApplicationIdValues";

export type EmployeeEntityResponse = {
  id: number;
  givenName: string;
  surname: string;
  mail: string;
  businessPhones: [];
  department: string;
  jobTitle: string;
};

export type EmployeeResponse = {
  value: EmployeeEntityResponse[];
};

const employeeApiUrl = 
   "users?$count=true&ConsistencyLevel=eventual&$filter=startsWith(companyName, 'ProAg') and accountEnabled eq true and NOT(jobTitle eq 'Agency Contractor') and department ne null and jobTitle ne null&$select=id,businessPhones,givenName,surname,mail,department,jobTitle,mobilePhone&$top=800"

   const jobTitleApiUrl = 
   "users?$count=true&ConsistencyLevel=eventual&$filter=startsWith(companyName, 'ProAg') and accountEnabled eq true and NOT(jobTitle eq 'Agency Contractor') and department ne null and jobTitle ne null&$select=id,jobTitle,department&$top=800"


   const getEmployeeList = async (apiGateway: ApiGateway) => 
    (await apiGateway.get(employeeApiUrl, Graph)) as EmployeeResponse

   const getJobTitleOptions = async (apiGateway: ApiGateway) =>
   (await apiGateway.get(jobTitleApiUrl, Graph));

   const getEmployeeDetail = async (apiGateway: ApiGateway, userId: string) => {
    const employeeDetailUrl = `users/${userId}/photo/$value`
    return await apiGateway.getImage(employeeDetailUrl, Graph)
   }
    

export const api = {
  getEmployeeList,
  getJobTitleOptions,
  getEmployeeDetail,
};