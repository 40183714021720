import { Nav, Dropdown, Col } from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
import facebookLogo from "../../images/facebook.png";
import twitterLogo from "../../images/twitter.png";
import linkedinLogo from "../../images/linkedin.png";
import pintrestLogo from "../../images/pinterest.png";
import youtubeLogo from "../../images/youtube.png";
import instagramLogo from "../../images/instagram.png";
import style from "./Layout.module.scss";
import { UseRolesContext } from "app/context";
import { useSystemLaunchQuery } from "./api/useSystemLaunchQuery";
import { getSystemLaunchLinksService } from "./service/getSystemLaunchLinksService";

export const NavigationBar = () => {
    const { role, isRoleLoading } = useContext(UseRolesContext);
    const [loadData, setLoadData] = useState(false)

    const { data } = useSystemLaunchQuery(loadData)
    const systemLaunchLinks = getSystemLaunchLinksService(data, role)

    const handleActiveClass = (href: any) => {
        const path = window.location.pathname || '';
        return href == path.replace(/\/$/, "") ? style.navlinksActive : style.navlinks;
    }

    return (
        <Nav data-testid="navbar" className={style.navigationbar}>
            <Col sm={8} md={7} lg={7} className={style.navOptions}>
                <Nav.Link className={handleActiveClass("/home")} href="/home">
                    Home
                </Nav.Link>
                <Nav.Link className={handleActiveClass("/links")} href="/links">
                    Links
                </Nav.Link>
                <Nav.Link className={handleActiveClass("/calendar")} href="/calendar">
                    ProAg Calendar
                </Nav.Link>
                <Nav.Link className={handleActiveClass("/employeeSearch")} href="/employeeSearch">
                    Employee Search
                </Nav.Link>
                <Nav.Link className={handleActiveClass("https://www.proag.com/contact/")} href="https://www.proag.com/contact/" target="_blank">
                    Contact Us
                </Nav.Link>
            </Col>
            <Col sm={4} md={4} lg={4} className={style.icons}>
                <Dropdown onClick={() => setLoadData(!loadData)}>
                    <Dropdown.Toggle className={style.systemLaunch}>System Launch</Dropdown.Toggle>
                    <Dropdown.Menu className={style.systemLaunchMenu}>
                    {
                        systemLaunchLinks?.map((link, index) => <Dropdown.Item key={`${link.title}-${index}`} href={link.link} target="_blank">{link.title}</Dropdown.Item>)
                    }
                    </Dropdown.Menu>
                </Dropdown>
                <a target="_blank" href="https://www.facebook.com/ProAgIns">
                    <img src={facebookLogo}></img>
                </a>
                <a target="_blank" href="https://twitter.com/ProAgIns">
                    <img src={twitterLogo}></img>
                </a>
                <a target="_blank" href="https://linkedin.com/company/proag">
                    <img src={linkedinLogo}></img>
                </a>
                <a target="_blank" href="https://www.pinterest.com/proagins/">
                    <img src={pintrestLogo}></img>
                </a>
                <a target="_blank" href="https://www.youtube.com/proagins">
                    <img src={youtubeLogo}></img>
                </a>
                <a target="_blank" href="https://www.instagram.com/proagins/">
                    <img src={instagramLogo}></img>
                </a>
            </Col>
        </Nav>
    );
};
