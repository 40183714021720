import { RoleName } from "app/apiGateway/useRole";
import { Page } from "./navigation";
import style from "./Page.module.scss";
import { UseRolesContext } from "app/context";
import { useContext } from "react";

/**
 * This component shows an error page when a role assignment is missing
 * @returns JSX.Element
 */

type RoleWise = {
  roleswiseRequired?: RoleName | RoleName[];
};

export const RoleError = () => {
  const { role, isRoleLoading } = useContext(UseRolesContext);
  if (isRoleLoading) {
    return null;
  }
  if(role===""|| role===undefined || role===null|| role.length===0){
    return (<div style={{ paddingTop: "80px" }}>
  <Page
    title="Error Encountered"
    content={<>
        We apologize. You don't appear to have access to the ProAgPortal
        intranet. Please contact the ProAg{" "}
        <a href="mailto: helpdesk@proag.com">Help Desk</a>.
        <p>
          <a href={(window as any).globalConfig?.Logout_Url}>
            Return to ProAgPortal
          </a>
        </p>
      </>}
    />
    </div>);
}
return (<div style={{ paddingTop: "80px" }}>
<Page
  title="Error Encountered"
  content={<>
      We apologize. You do not have Administrator Access. Please contact the ProAg{" "}
      <a href="mailto: helpdesk@proag.com"> Help Desk</a>.
      <p>
        <a href={(window as any).globalConfig?.Logout_Url}>
          Return to ProAgPortal
        </a>
      </p>
    </>}
  />
  </div>);
};
