import { ApiGateway } from "app/apiGateway/useApiGateway";
import { Box, Graph, Document } from "app/apiGateway/constants/ApplicationIdValues";
import { RoleName } from 'app/apiGateway/useRole';

export type HomeEntityResponse = {
  id: number;
  type: string;
  name: string;
  description: string;
  modified_at: string;
  item_status: string;
}[];
export type HomeResponse = {
  entries: HomeEntityResponse[];
  limit: number;
  //offset: number;
  // order: [];
  //total_count: number;
};

export type HomeDocumentResponse = {
  files: HomeDocumentEntity[];
  metadataFields: {};
}

export type HomeDocumentEntity = {
  id: number;
  fileType: string;
  name: string;
  description: string;
  modifiedAt: string;
  contentModifiedAt: string;
  createdAt: string;
  contentCreatedAt: string;
  metadata: MetadataEntity[];
}[];

export type MetadataEntity = {
  templateName: string;
  metadataFields: MetadataKeyValue[];
}
export type MetadataKeyValue = {
  key: string;
  value: string[];
}
export type EventEntityResponse = {
  start: {};
  subject: string;
};
export type EventResponse = {
  value: EventEntityResponse[];
};
export type HomeAlertsResponse = {
  id: number;
  type: string;
  name: string;
  status: string;
  modified_at: string;
  item_status: string;
}[];

let apiGatewayGraphURL = (window as any).globalConfig.ApiGatewayGraph_URL;

// latest announcements
const announcementFilter = {
  contentType: ["Announcement News"],
  status: ["Published"]
};
const announcementUrl =
      "categories/proagportal/search?query=" +
      "&offset=0&limit=5&template=proagportalmetadata&sort=modified_at&isMetadataRequested=false" + 
      "&sort_direction=desc&mdFilters=" + encodeURIComponent(JSON.stringify(announcementFilter));


// TODO: make this configurable
// latest documents
const latestDocumentFilter = {
  contentType: ["Form Letters", "Forms", "Manuals Tips Instructions", "Processes TOPE", "Release Notes", "Systems", "Templates", "Worksheets"],
  status: ["Published"]
};

const latestDocumentUrl =
      "categories/proagportal/search?query=" +
      "&offset=0&limit=10&template=proagportalmetadata&sort=modified_at&isMetadataRequested=false" + 
      "&sort_direction=desc&mdFilters=" + encodeURIComponent(JSON.stringify(latestDocumentFilter));

let currentTime = new Date().toISOString();

//Alerts
const globalAlertAPI = `calendar/events?$filter=
  (start/dateTime le '`+currentTime+`' and end/dateTime ge '`+currentTime+`') and 
    (categories/any (x:x eq 'Global Notifications')) 
  &$select= subject, location, hasAttachments`;

const internalAlertAPI= `calendar/events?$filter=
(start/dateTime le '`+currentTime+`' and end/dateTime ge '`+currentTime+`') and 
  (categories/any (x:x eq 'Global Notifications') or categories/any (x:x eq 'Internal Only Notifications')) 
&$select= subject, location, hasAttachments`;

const getAlertApiURL = (role : RoleName | undefined) =>{
  
  var eventsApiUrl; 
  if(role === 'Internal' || role === 'Administrator'){
    eventsApiUrl = apiGatewayGraphURL + internalAlertAPI;
  }
  else{
    eventsApiUrl = apiGatewayGraphURL + globalAlertAPI;
  } 
  return eventsApiUrl;
}

// latest events
 const getEventsApiURL = (role : RoleName | undefined) =>{
    const requestDate = new Date();
    var firstDaySixthMonth = new Date(requestDate.getFullYear(), requestDate.getMonth() + 6, 1).toISOString();
    const todayDate = requestDate.toISOString();
    const eventsApiUrl1 = apiGatewayGraphURL + `calendar/calendarView?startDateTime=`+todayDate+`&endDateTime=`+firstDaySixthMonth+`&$select= categories,recurrence,subject,body,start,end,location,hasAttachments &orderBy=start/dateTime &$top=50`
    return eventsApiUrl1;
  }

const getHomeAlerts = async (apiGateway: ApiGateway, role: RoleName | undefined) =>
  (await apiGateway.get(getAlertApiURL(role), Graph));

const getUpcomingEvents = async (apiGateway: ApiGateway, role: RoleName | undefined) =>
  (await apiGateway.get(getEventsApiURL(role), Graph));

const getAnnouncements = async (apiGateway: ApiGateway) => 
    (await apiGateway.get(announcementUrl, Document));

const getHomeDocuments = async (apiGateway: ApiGateway) => 
    (await apiGateway.get(latestDocumentUrl, Document)) as HomeDocumentResponse;

export const api = {
  getHomeAlerts,
  getUpcomingEvents,
  getAnnouncements,
  getHomeDocuments
};