import { ExclamationCircle } from "react-bootstrap-icons";
import { FallbackProps } from "react-error-boundary";
import style from "views/Calendar/Calendar.module.scss";


export const CalendarErrorFallback: React.FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
  }: FallbackProps) => {
    return (
      <>
        <div>
          <div className={style.container}>
            <div className={style.message}>
              <ExclamationCircle className={style.icon} />
              <div>
                We apologize. Something went wrong with your request. Please
                contact the ProAg <a href="mailto: helpdesk@proag.com">Help Desk</a>.
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };