import { useQuery } from '@tanstack/react-query';
import { useApiGateway } from 'app/apiGateway/useApiGateway';
import { toast } from 'react-toastify';
import { CalendarEventsRequest, api } from './api';
import { useErrorBoundary } from 'react-error-boundary';

export const useCalendarEventsQuery = (_calendarEventsRequest: CalendarEventsRequest) => {
  const apiGateway = useApiGateway();
  const { showBoundary } = useErrorBoundary();

  const { data, isFetching, isSuccess } = useQuery(
    [_calendarEventsRequest],
    async () =>    
         await api.getCalendarEvents(apiGateway, _calendarEventsRequest),
    {      
      onSuccess: (data) => {
        if (!data.value) toast.info('You do not have any Upcoming Events.');
      },
      // source: https://www.npmjs.com/package/react-error-boundary
      // Errors thrown in event handlers, or after async code has run, will not be caught.
      // hook showBoundary to pass those errors to the nearest error boundary
      onError: (error: any) => {showBoundary(error)},
    }
  );

  return {
    calEvent: data,
    isLoading: isFetching,  
    isSuccess
  };
};
